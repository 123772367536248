import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { BoldLink, CapitalizedText } from '../shared/TextComponents'
import { Button, ButtonSize, ButtonStyle } from '../shared/buttons/Button'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import goBack from '../../assets/svg/actions/return.svg'
import newTabIcon from '../../assets/svg/actions/open-in-new-tab.svg'
import { capitalizeFirstLetter } from '../../utils/formats'

interface BackButtonProps {
  clickHandler: () => void
  disabled: boolean
}

export const LoginBackButton = ({ clickHandler, disabled }: BackButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      value={
        <div className={'flex gap-1.5 items-center'}>
          <CustomIcon iconType={IconType.VECTOR} path={goBack} styles={'w-4 h-4 bg-gray-200 group-hover:bg-gray-50'} />
          <CapitalizedText>{t('login.return')}</CapitalizedText>
        </div>
      }
      buttonStyle={ButtonStyle.GHOST}
      size={ButtonSize.XSMALL}
      clickHandler={clickHandler}
      disabled={disabled}
    />
  )
}

interface SpotterDocumentationLinkProps {
  path?: string
  label?: string
  capitalize?: boolean
  useIcon?: boolean
}

export const SpotterDocumentationLink = ({ path, label, capitalize, useIcon }: SpotterDocumentationLinkProps) => {
  const { t } = useTranslation()
  const linkLabel = label ? label : t('common.documentation')
  return (
    <BoldLink
      className={useIcon ? 'group flex gap-0.5 items-center' : ''}
      href={`https://spotterdocs.cloud2.fi/data/${path || ''}`}
      target={'_blank'}
      rel={'noreferrer'}
    >
      {capitalize ? capitalizeFirstLetter(linkLabel) : linkLabel}
      {useIcon && <CustomIcon path={newTabIcon} styles={'w-3 h-3 bg-primary-400 -mt-1 group-hover:bg-primary-300'} />}
    </BoldLink>
  )
}

interface LoginFlowContainerProps {
  title: string
  instructions?: string
  children: React.ReactNode
}

export const LoginFlowContainer = ({ title, instructions, children }: LoginFlowContainerProps) => {
  return (
    <div className={'flex flex-col items-center justify-center gap-6'}>
      <LoginInstructions title={title} instructions={instructions} />
      {children}
    </div>
  )
}

interface LoginInstructionsProps {
  title: string
  instructions?: string
}

export const LoginInstructions = ({ title, instructions }: LoginInstructionsProps) => {
  return (
    <div className={'flex flex-col gap-3 w-full justify-center text-center'}>
      <LoginTitle>{title}</LoginTitle>
      {instructions && <LoginInstruction>{instructions}</LoginInstruction>}
    </div>
  )
}

export const LoginTitle = styled(CapitalizedText)`
  ${tw`text-112 font-semibold text-gray-50`}
`

export const LoginInstruction = styled(CapitalizedText)`
  ${tw`w-full text-gray-200`}
`

export const LoginFormContainer = styled.form`
  ${tw`flex flex-col items-center gap-3 mx-auto`}
  div {
    ${tw`w-full`}
  }

  button {
    ${tw`mt-5`}
  }
`
