import { NestedPageHeader } from '../shared/NestedPageHeader'
import { GrayText, WhiteText } from '../shared/TextComponents'
import { Button, ButtonSize, ButtonStyle, ButtonType, DeleteButton } from '../shared/buttons/Button'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import addIcon from '../../assets/svg/symbols/plus.svg'
import stopIcon from '../../assets/svg/gestures/stop.svg'
import infoIcon from '../../assets/svg/symbols/info.svg'
import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Tenant } from '../../api/system-admin'
import {
  addSsoPoolForTenant,
  addTenant,
  AddTenantRequest,
  changeTenantName,
  changeTenantRestrictedAccess,
  deleteSysAdminsAccessToTenant,
  deleteTenant,
  deleteTenantSsoPool,
  getAllTenantDetails,
  TenantDetails,
  TenantSsoPool
} from '../../api/master-admin'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { ModalContext } from '../../state/context/ModalContext'
import { ModalActions } from '../shared/modal/Modal'
import { useForm, UseFormRegister } from 'react-hook-form'
import { CustomInput, CustomLabel } from '../shared/filters/FormComponents'
import styled from 'styled-components'
import tw from 'twin.macro'
import { CheckboxOption } from '../shared/buttons/CheckboxOption'
import { DimmedContainer, TenantHeader } from './Shared'
import { LabeledSearchInput } from '../shared/filters/SearchInput'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { SwitchButton } from '../shared/buttons/SwitchButton'
import { StepMeter } from '../shared/StepMeter'
import { Layout, SubLayoutContentWrapper } from '../../layout/Layout'
import editIcon from '../../assets/svg/actions/edit.svg'
import { ClipboardCopy } from '../shared/ClipboardCopy'
import { ScrollContainer } from '../shared/containers/ScrollContainer'

export const MasterAdminTenants = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const handleError = useErrorHandling()
  const [tenants, setTenants] = useState<TenantDetails[]>([])
  const [filteredTenants, setFilteredTenants] = useState<TenantDetails[]>([])
  const [selectedTenant, setSelectedTenant] = useState<Tenant>()
  const [searchText, setSearchText] = useState('')
  const [addSsoStep, setAddSsoStep] = useState<number>(0)

  useEffect(() => {
    getAllTenantDetails(createCancelToken().token).then(setTenants).catch(handleError)
  }, [createCancelToken, handleError])

  useEffect(() => {
    if (searchText !== '') {
      setFilteredTenants(
        tenants.filter(
          tenant =>
            tenant.tenant.name.toLowerCase().includes(searchText.toLowerCase()) ||
            tenant.tenant.id.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    } else setFilteredTenants(tenants)
  }, [tenants, searchText])

  useEffect(() => {
    addSsoStep >= 1 && openAddSsoModal()
  }, [addSsoStep])

  const openAddTenantModal = () => {
    const addAction = (tenant: AddTenantRequest) =>
      addTenant(tenant, createCancelToken().token)
        .then(resp => {
          setTenants(resp)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('masterAdmin.tenants.toast.addTenantSuccess', { name: tenant.name })
          })
          setModal(null)
        })
        .catch(handleError)

    setModal({
      header: t('masterAdmin.tenants.modal.addNewTenant'),
      body: <AddTenantModal submitAction={addAction} />
    })
  }

  const openDeleteModal = (tenant: Tenant) => {
    const deleteAction = () => {
      deleteTenant(tenant.id, createCancelToken().token).then(() => {
        getAllTenantDetails(createCancelToken().token).then(setTenants).catch(handleError)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('masterAdmin.tenants.toast.deleteTenantSuccess', { name: tenant.name })
        })
        setModal(null)
      })
    }

    setModal({
      header: t('masterAdmin.tenants.modal.deleteTenant', { name: tenant.name }),
      body: <DeleteTenantModal deleteAction={deleteAction} />
    })
  }

  const openAddSsoModal = () => {
    if (selectedTenant) {
      if (addSsoStep === 0) {
        setAddSsoStep(1)
      } else {
        const addSsoPool = (ssoPool: TenantSsoPool) => {
          selectedTenant &&
            addSsoPoolForTenant(selectedTenant.id, ssoPool, createCancelToken().token).then(resp => {
              setTenants(resp)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('masterAdmin.tenants.toast.addSsoPoolSuccess', { name: selectedTenant.name })
              })
              setModal(null)
            })
        }

        setModal({
          header: (
            <StepMeter
              currentStep={addSsoStep}
              totalSteps={4}
              description={t(`masterAdmin.tenants.modal.addSsoPoolFor`, { tenant: selectedTenant.name })}
            />
          ),
          body: <AddSsoModal step={addSsoStep} setStep={setAddSsoStep} submitAction={addSsoPool} />
        })
      }
    }
  }

  const openDeleteSsoModal = (tenant: Tenant) => {
    const deleteAction = () => {
      deleteTenantSsoPool(tenant.id, createCancelToken().token).then(() => {
        getAllTenantDetails(createCancelToken().token).then(setTenants).catch(handleError)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('masterAdmin.tenants.toast.deleteSsoPoolSuccess', { name: tenant.name })
        })
        setModal(null)
      })
    }

    setModal({
      header: t('masterAdmin.tenants.deleteSsoPool', { name: tenant.name }),
      body: (
        <>
          <WhiteText className={'first-letter:capitalize text-center max-w-120'}>
            {t('masterAdmin.tenants.modal.confirmSsoDelete', { tenant: tenant.name })}
          </WhiteText>
          <ModalActions>
            <Button
              size={ButtonSize.XSMALL}
              value={t('common.cancel')}
              buttonStyle={ButtonStyle.SECONDARY}
              clickHandler={() => setModal(null)}
              type={ButtonType.FORM}
            />
            <Button
              size={ButtonSize.XSMALL}
              value={t('common.delete')}
              clickHandler={deleteAction}
              type={ButtonType.FORM}
            />
          </ModalActions>
        </>
      )
    })
  }

  const openEditNameModal = () => {
    const changeNameAction = (tenant: Tenant) => {
      changeTenantName(tenant.id, tenant.name, createCancelToken().token)
        .then(resp => {
          setTenants(resp)
          setSelectedTenant(resp.find(t => t.tenant.id === tenant.id)?.tenant)
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('masterAdmin.tenants.toast.updateTenantNameSuccess', { name: tenant.name })
          })
        })
        .catch(handleError)
    }
    selectedTenant &&
      setModal({
        header: t('masterAdmin.tenants.editName'),
        body: <EditNameModal tenant={selectedTenant} submitAction={changeNameAction} />
      })
  }

  const updateTenantAccess = (tenant: TenantDetails) => {
    const newAccess = !tenant.hasRestrictedAccess
    changeTenantRestrictedAccess(tenant.tenant.id, newAccess, createCancelToken().token)
      .then(resp => {
        setTenants(resp)
        newAccess
          ? setMessage({
              type: MessageType.SUCCESS,
              message: t('masterAdmin.tenants.toast.updateTenantAccessSuccess', { name: tenant.tenant.name })
            })
          : setMessage({
              type: MessageType.SUCCESS,
              message: t('masterAdmin.tenants.toast.accessGrantedToAll', { name: tenant.tenant.name })
            })
      })
      .catch(handleError)
  }

  const deleteTenantAccess = (tenant: Tenant) => {
    deleteSysAdminsAccessToTenant(tenant.id, createCancelToken().token)
      .then(resp => {
        setTenants(resp)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('masterAdmin.tenants.toast.deleteAccessFromAllSuccess', { name: tenant.name })
        })
        setModal(null)
      })
      .catch(handleError)
  }

  return (
    <Layout type={'sub'}>
      <NestedPageHeader
        mainHeading={t('masterAdmin.tenants.heading')}
        capitalize={true}
        subHeading={<GrayText>{t('masterAdmin.tenants.subheading')} 🏰</GrayText>}
        actions={
          <Button
            value={
              <CustomIcon
                path={addIcon}
                iconType={IconType.VECTOR}
                styles={'w-5 h-5 bg-gray-50'}
                alt={t('masterAdmin.tenants.addNewTenant')}
              />
            }
            type={ButtonType.ICON}
            clickHandler={openAddTenantModal}
          />
        }
      />
      <SubLayoutContentWrapper>
        <div className={'pl-2'}>
          <LabeledSearchInput
            label={t('masterAdmin.tenants.searchTenant')}
            placeholder={t('masterAdmin.tenants.searchPlaceholder')}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </div>
        <TenantList
          filteredTenants={filteredTenants}
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
          updateTenantAccess={updateTenantAccess}
          deleteTenantAccess={deleteTenantAccess}
          openAddSsoModal={openAddSsoModal}
          openDeleteSsoModal={openDeleteSsoModal}
          openDeleteModal={openDeleteModal}
          openEditNameModal={openEditNameModal}
        />
      </SubLayoutContentWrapper>
    </Layout>
  )
}

interface TenantListProps {
  filteredTenants: TenantDetails[]
  selectedTenant: Tenant | undefined
  setSelectedTenant: (tenant: Tenant | undefined) => void
  updateTenantAccess: (tenant: TenantDetails) => void
  deleteTenantAccess: (tenant: Tenant) => void
  openAddSsoModal: (tenant: Tenant) => void
  openDeleteSsoModal: (tenant: Tenant) => void
  openDeleteModal: (tenant: Tenant) => void
  openEditNameModal: (tenant: Tenant) => void
}

const TenantList = ({
  filteredTenants,
  selectedTenant,
  setSelectedTenant,
  updateTenantAccess,
  deleteTenantAccess,
  openAddSsoModal,
  openDeleteSsoModal,
  openDeleteModal,
  openEditNameModal
}: TenantListProps) => {
  const { t } = useTranslation()
  return (
    <ScrollContainer className={'max-h-[70vh] w-fit'}>
      {filteredTenants.length ? (
        filteredTenants.map(tenantDetails => (
          <div key={tenantDetails.tenant.id} className={`flex flex-col p-2 w-full max-w-150 md:min-w-150`}>
            <TenantHeader
              tenant={tenantDetails.tenant}
              selectedTenant={selectedTenant}
              setSelectedTenant={setSelectedTenant}
              rightComponent={
                selectedTenant &&
                selectedTenant.id === tenantDetails.tenant.id && (
                  <div className={'flex items-center gap-3'}>
                    <Button
                      type={ButtonType.ICON}
                      clickHandler={e => {
                        e.stopPropagation()
                        openEditNameModal(tenantDetails.tenant)
                      }}
                      buttonStyle={ButtonStyle.GHOST}
                      value={
                        <CustomIcon
                          iconType={IconType.VECTOR}
                          path={editIcon}
                          alt={t('masterAdmin.tenants.editName')}
                          styles={'bg-gray-50 w-5 h-5'}
                        />
                      }
                    />
                    <DeleteButton
                      size={5}
                      clickHandler={() => openDeleteModal(tenantDetails.tenant)}
                      tooltipText={t('common.delete')}
                    />
                  </div>
                )
              }
            />
            {selectedTenant && selectedTenant.id === tenantDetails.tenant.id && (
              <TenantProperties
                tenantDetails={tenantDetails}
                updateTenantAccess={updateTenantAccess}
                deleteAccessToTenant={deleteTenantAccess}
                openAddSsoModal={openAddSsoModal}
                openDeleteSsoModal={openDeleteSsoModal}
              />
            )}
          </div>
        ))
      ) : (
        <MissingDataNotification displayText={t('masterAdmin.tenants.tenantNotFound')} />
      )}
    </ScrollContainer>
  )
}

interface TenantPropertiesProps {
  tenantDetails: TenantDetails
  updateTenantAccess: (tenant: TenantDetails) => void
  deleteAccessToTenant: (tenant: Tenant) => void
  openAddSsoModal: (tenant: Tenant) => void
  openDeleteSsoModal: (tenant: Tenant) => void
}

const TenantProperties = ({
  tenantDetails,
  updateTenantAccess,
  deleteAccessToTenant,
  openAddSsoModal,
  openDeleteSsoModal
}: TenantPropertiesProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)

  const deleteAccessAction = () => {
    setModal({
      header: t('masterAdmin.tenants.deleteAccess'),
      body: (
        <>
          <GrayText className={'w-full text-center'}>
            <Trans>{t('masterAdmin.tenants.modal.deleteAccessConfirm', { name: tenantDetails.tenant.name })}</Trans>
          </GrayText>
          <ModalActions>
            <Button
              size={ButtonSize.XSMALL}
              value={t('masterAdmin.tenants.deleteAccess')}
              clickHandler={() => deleteAccessToTenant(tenantDetails.tenant)}
            />
          </ModalActions>
        </>
      )
    })
  }

  return (
    <DimmedContainer className={'flex flex-col gap-8'}>
      <div className={'flex flex-col gap-2'}>
        <WhiteText className={'first-letter:capitalize font-semibold'}>
          {t('masterAdmin.tenants.restrictedAccess')}
        </WhiteText>
        <div className={'flex items-center gap-3 min-h-14'}>
          <SwitchButton
            label=''
            checked={tenantDetails.hasRestrictedAccess}
            clickHandler={() => updateTenantAccess(tenantDetails)}
          />
          <GrayText className={'text-90'}>
            {t(`masterAdmin.tenants.modal.restrictedAccessDescription_${tenantDetails.hasRestrictedAccess}`)}
          </GrayText>
        </div>
        <div key={tenantDetails.accessesGranted} className={'flex items-center gap-3'}>
          <Button
            size={ButtonSize.XSMALL}
            value={t('masterAdmin.tenants.deleteAccess')}
            clickHandler={() => deleteAccessAction()}
            disabled={tenantDetails.accessesGranted === 0}
          />
          <GrayText className={'text-90'}>
            {tenantDetails.accessesGranted === 0
              ? t('masterAdmin.tenants.noAccessesGranted')
              : t('masterAdmin.tenants.deleteAccessDescription')}
          </GrayText>
        </div>
      </div>
      {tenantDetails.ssoPool && (
        <div>
          <WhiteText className={'font-semibold'}>{t('masterAdmin.tenants.ssoPool')}</WhiteText>
          <LabelValueRow label={t('masterAdmin.tenants.cognitoRegion')} value={tenantDetails.ssoPool.cognitoRegion} />
          <LabelValueRow label={t('masterAdmin.tenants.cognitoPoolId')} value={tenantDetails.ssoPool.cognitoPoolId} />
          <LabelValueRow
            label={t('masterAdmin.tenants.cognitoPoolDomain')}
            value={tenantDetails.ssoPool.cognitoPoolDomain}
          />
          <LabelValueRow label={t('masterAdmin.tenants.cognitoIdp')} value={tenantDetails.ssoPool.cognitoIdp} />
          <LabelValueRow
            label={t('masterAdmin.tenants.cognitoClientId')}
            value={tenantDetails.ssoPool.cognitoClientId}
          />
          <LabelValueRow
            label={t('masterAdmin.tenants.ssoDomains')}
            value={tenantDetails.ssoPool.ssoDomains.join(', ')}
          />
        </div>
      )}
      <Button
        value={tenantDetails.ssoPool ? t('masterAdmin.tenants.deleteSsoPool') : t('masterAdmin.tenants.addSsoPool')}
        size={ButtonSize.XSMALL}
        clickHandler={() =>
          tenantDetails.ssoPool ? openDeleteSsoModal(tenantDetails.tenant) : openAddSsoModal(tenantDetails.tenant)
        }
      />
    </DimmedContainer>
  )
}

interface LabelValueRowProps {
  label: string
  value: string
}

const LabelValueRow = ({ label, value }: LabelValueRowProps) => {
  return (
    <div
      className={
        'flex items-center justify-between w-full gap-4 py-2 border-b border-gray-500/60 border-dotted last:border-none'
      }
    >
      <GrayText>{label}</GrayText>
      <WhiteText>{value}</WhiteText>
    </div>
  )
}

interface AddTenantModalProps {
  submitAction: (tenant: AddTenantRequest) => void
}

const AddTenantModal = ({ submitAction }: AddTenantModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const [restrictedAccess, setRestrictedAccess] = useState(true)
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = useForm<AddTenantRequest>()

  return (
    <>
      <form className={'flex flex-col gap-6'}>
        <div>
          <CustomLabel>{t('common.name')} *</CustomLabel>
          <CustomInput
            {...register('name', {
              required: true
            })}
            autoFocus={true}
          />
        </div>
        <div>
          <CustomLabel>{t('masterAdmin.tenants.modal.restrictedAccess')} *</CustomLabel>
          <div className={'flex w-full gap-8 items-center'}>
            <RestrictedAccessOptions
              register={register}
              restrictedAccess={restrictedAccess}
              setRestrictedAccess={setRestrictedAccess}
            />
            <GrayText className={'max-w-85 text-90'}>
              {t(`masterAdmin.tenants.modal.restrictedAccessDescription_${restrictedAccess}`)}
            </GrayText>
          </div>
        </div>
      </form>
      <ModalActions>
        <Button
          size={ButtonSize.XSMALL}
          value={t('common.cancel')}
          buttonStyle={ButtonStyle.SECONDARY}
          clickHandler={() => setModal(null)}
          type={ButtonType.FORM}
        />
        <Button
          size={ButtonSize.XSMALL}
          value={t('masterAdmin.tenants.modal.addTenant')}
          clickHandler={handleSubmit(submitAction)}
          disabled={!isValid || isSubmitting}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </>
  )
}

interface AccessRadioButtonsProps {
  register: UseFormRegister<AddTenantRequest>
  restrictedAccess: boolean
  setRestrictedAccess: (restricted: boolean) => void
}

const RestrictedAccessOptions = ({ register, restrictedAccess, setRestrictedAccess }: AccessRadioButtonsProps) => {
  const { t } = useTranslation()

  return (
    <div className={'max-w-max'}>
      <div className={'flex flex-col gap-3'}>
        <AccessRadioButton
          checked={restrictedAccess}
          value={t('common.yes')}
          onChange={() => {
            setRestrictedAccess(true)
            register('hasRestrictedAccess')
          }}
        />
        <AccessRadioButton
          checked={!restrictedAccess}
          value={t('common.no')}
          onChange={() => {
            setRestrictedAccess(false)
            register('hasRestrictedAccess')
          }}
        />
      </div>
    </div>
  )
}

interface RadioButtonProps {
  checked: boolean
  onChange: () => void
  value: string
}

const AccessRadioButton = ({ checked, onChange, value }: RadioButtonProps) => {
  return (
    <RadioButtonWrapper checked={checked} onClick={onChange}>
      <input readOnly type={'radio'} checked={checked} />
      {value}
    </RadioButtonWrapper>
  )
}

interface ContainerProps {
  checked: boolean
}

const RadioButtonWrapper = styled(WhiteText)<ContainerProps>`
  ${tw`capitalize text-90 w-16 text-center px-3 py-1 rounded transition-all ease duration-200 cursor-pointer`}
  ${({ checked }) =>
    checked
      ? tw`border border-primary-500 shadow-sm font-semibold`
      : tw`border border-primary-500/20 hover:border-primary-500/60`}
  input {
    ${tw`appearance-none`}
    ${({ checked }) => checked && tw`cursor-default`}
  }
`

interface DeleteTenantModalProps {
  deleteAction: () => void
}

const DeleteTenantModal = ({ deleteAction }: DeleteTenantModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const [deleteDisabled, setDeleteDisabled] = useState(true)

  return (
    <>
      <div className={'flex gap-5 max-w-125'}>
        <CustomIcon path={stopIcon} styles={'w-12 h-12 bg-warn-100'} />
        <div className={'flex flex-col gap-4'}>
          <WhiteText className={'first-letter:capitalize'}>{t('masterAdmin.tenants.modal.confirmDelete')}</WhiteText>
          <CheckboxOption
            type={'small'}
            capitalize={true}
            label={t('masterAdmin.tenants.modal.confirmDeleteCheckbox')}
            clickHandler={() => setDeleteDisabled(!deleteDisabled)}
            checked={!deleteDisabled}
          />
        </div>
      </div>
      <ModalActions>
        <Button
          size={ButtonSize.XSMALL}
          value={t('common.cancel')}
          buttonStyle={ButtonStyle.SECONDARY}
          clickHandler={() => setModal(null)}
          type={ButtonType.FORM}
        />
        <Button
          size={ButtonSize.XSMALL}
          value={t('common.delete')}
          clickHandler={deleteAction}
          disabled={deleteDisabled}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </>
  )
}

interface AddSsoModalProps {
  step: number
  setStep: (step: number) => void
  submitAction: (ssoPool: TenantSsoPool) => void
}

const AddSsoModal = ({ step, setStep, submitAction }: AddSsoModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const [propertiesAreCorrect, setPropertiesAreCorrect] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid, isSubmitting }
  } = useForm<TenantSsoPool>()

  return (
    <>
      <form className={'flex flex-col gap-5'}>
        <WhiteText className={'first-letter:capitalize font-semibold text-center'}>
          {step < 4
            ? t('masterAdmin.tenants.modal.addSsoPoolProperties')
            : t('masterAdmin.tenants.modal.checkSsoProperties')}
        </WhiteText>
        <div className={'flex flex-col gap-5'}>
          {step === 1 ? (
            <>
              <div>
                <CustomLabel>{t('masterAdmin.tenants.cognitoRegion')} *</CustomLabel>
                <CustomInput
                  key={step}
                  placeholder={`${t('common.exempliGratia')} eu-north-1`}
                  {...register('cognitoRegion', {
                    required: true
                  })}
                  autoFocus={true}
                />
                <InputDescription description={t('masterAdmin.tenants.modal.ssoPoolRegionDescription')} />
              </div>
              <div>
                <CustomLabel>{t('masterAdmin.tenants.cognitoPoolId')} *</CustomLabel>
                <CustomInput
                  key={step}
                  placeholder={`${t('common.exempliGratia')} eu-north-1_4IvJ2cC8A`}
                  {...register('cognitoPoolId', {
                    required: true
                  })}
                />
                <InputDescription description={t('masterAdmin.tenants.modal.ssoPoolIdDescription')} />
              </div>
            </>
          ) : step === 2 ? (
            <>
              <div>
                <CustomLabel>{t('masterAdmin.tenants.cognitoPoolDomain')} *</CustomLabel>
                <CustomInput
                  key={step}
                  placeholder={`${t('common.exempliGratia')} spotter-cloud2-sso`}
                  {...register('cognitoPoolDomain', {
                    required: true
                  })}
                />
                <InputDescription description={t('masterAdmin.tenants.modal.ssoPoolDomainDescription')} />
              </div>
              <div>
                <CustomLabel>{t('masterAdmin.tenants.cognitoClientId')} *</CustomLabel>
                <CustomInput
                  key={step}
                  placeholder={`${t('common.exempliGratia')} 7f7hp1i5eg1d314nvhsdqgk4ab`}
                  {...register('cognitoClientId', {
                    required: true
                  })}
                />
                <InputDescription description={t('masterAdmin.tenants.modal.ssoPoolClientIdDescription')} />
              </div>
            </>
          ) : step === 3 ? (
            <>
              <div>
                <CustomLabel>{t('masterAdmin.tenants.cognitoIdp')} *</CustomLabel>
                <CustomInput
                  key={step}
                  placeholder={`${t('common.exempliGratia')} EntraID`}
                  {...register('cognitoIdp', {
                    required: true
                  })}
                />
                <InputDescription description={t('masterAdmin.tenants.modal.ssoPoolIdpDescription')} />
              </div>
              <div>
                <CustomLabel>{t('masterAdmin.tenants.ssoDomains')} *</CustomLabel>
                <CustomInput
                  key={step}
                  placeholder={`${t('common.exempliGratia')} cloud2.com, spotter.com`}
                  {...register('ssoDomains', {
                    required: true,
                    onBlur: e => {
                      setValue(
                        'ssoDomains',
                        e.target.value.split(',').map((domain: string) => domain.trim())
                      )
                    }
                  })}
                />
                <InputDescription description={t('masterAdmin.tenants.modal.ssoPoolDomainsDescription')} />
              </div>
            </>
          ) : (
            <>
              <div>
                <LabelValueRow label={t('masterAdmin.tenants.cognitoRegion')} value={getValues('cognitoRegion')} />
                <LabelValueRow label={t('masterAdmin.tenants.cognitoPoolId')} value={getValues('cognitoPoolId')} />
                <LabelValueRow label={t('masterAdmin.tenants.cognitoIdp')} value={getValues('cognitoIdp')} />
                <LabelValueRow label={t('masterAdmin.tenants.cognitoClientId')} value={getValues('cognitoClientId')} />
                <LabelValueRow
                  label={t('masterAdmin.tenants.cognitoPoolDomain')}
                  value={getValues('cognitoPoolDomain')}
                />
                <LabelValueRow label={t('masterAdmin.tenants.ssoDomains')} value={getValues('ssoDomains').toString()} />
              </div>
              <CheckboxOption
                type={'small'}
                clickHandler={() => setPropertiesAreCorrect(!propertiesAreCorrect)}
                checked={propertiesAreCorrect}
                label={t('masterAdmin.tenants.modal.ssoPoolPropertiesCheck')}
              />
            </>
          )}
        </div>
      </form>
      <ModalActions>
        <Button
          size={ButtonSize.XSMALL}
          value={step === 1 ? t('common.cancel') : t('common.prev')}
          buttonStyle={ButtonStyle.SECONDARY}
          clickHandler={event => {
            if (step === 1) setModal(null)
            else {
              event.preventDefault()
              setStep(step - 1)
            }
          }}
          type={ButtonType.FORM}
        />
        <Button
          size={ButtonSize.XSMALL}
          value={step === 4 ? t('masterAdmin.tenants.addSsoPool') : t('common.next')}
          clickHandler={event => {
            if (step === 4) handleSubmit(submitAction)()
            else {
              event.preventDefault()
              setStep(step + 1)
            }
          }}
          disabled={!isValid || isSubmitting || (step === 4 && !propertiesAreCorrect)}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </>
  )
}

interface EditNameModalProps {
  tenant: Tenant
  submitAction: (tenant: Tenant) => void
}

const EditNameModal = ({ tenant, submitAction }: EditNameModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty }
  } = useForm<Tenant>({
    defaultValues: {
      id: tenant.id,
      name: tenant.name
    }
  })

  return (
    <>
      <form className={'flex flex-col gap-6 pb-3'}>
        <div>
          <CustomLabel>{t('masterAdmin.tenants.tenantId')}</CustomLabel>
          <WhiteText>
            <ClipboardCopy limit={40} copyText={tenant.id} />
          </WhiteText>
        </div>
        <div>
          <CustomLabel>{t('common.name')} *</CustomLabel>
          <CustomInput
            {...register('name', {
              required: true,
              minLength: 1
            })}
            autoFocus={true}
          />
        </div>
      </form>
      <ModalActions>
        <Button
          size={ButtonSize.XSMALL}
          value={t('common.cancel')}
          buttonStyle={ButtonStyle.SECONDARY}
          clickHandler={() => setModal(null)}
          type={ButtonType.FORM}
        />
        <Button
          size={ButtonSize.XSMALL}
          value={t('common.saveChanges')}
          clickHandler={handleSubmit(submitAction)}
          disabled={!isDirty || !isValid || isSubmitting}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </>
  )
}

interface InputDescriptionProps {
  description: string
}

const InputDescription = ({ description }: InputDescriptionProps) => {
  return (
    <div className={'flex items-center gap-1 py-1'}>
      <CustomIcon path={infoIcon} styles={'w-3.5 h-3.5 bg-gray-100'} />
      <GrayText className={'italic text-80'}>{description}</GrayText>
    </div>
  )
}
