import React, { useContext, useEffect, useRef, useState } from 'react'
import { GrayText, Heading2, WhiteText } from '../../shared/TextComponents'
import { CustomIcon } from '../../shared/CustomIcon'
import { CostAnomalyRuleTargetMenu } from './TenantCostAnomalyRules'
import { FilterType } from '../../shared/filters/Filters'
import { Button, ButtonSize, ButtonStyle, ButtonType, DeleteButton } from '../../shared/buttons/Button'
import {
  CostAnomalyLimits,
  CostAnomalyProject,
  editTenantCostAnomalyRule,
  getCostAnomalyTargetOptions,
  getTenantCostAnomalyRule,
  TenantCostAnomalyRule
} from '../../../api/admin/alerts'
import { CostAnomalyBudgetInputs, CostAnomalyLimitInputs, NumberInput } from '../../shared/CostAnomalyInputs'
import { ChangeType } from '../../../api/notifications'
import { FilterOption, FilterSelect, GroupedOptions } from '../../shared/filters/FilterSelect'
import { useTranslation } from 'react-i18next'
import { Vendor } from '../../../utils/vendors'
import { emailRegExp, formatDate, formatNumber, getLastMonth } from '../../../utils/formats'
import tw from 'twin.macro'
import styled from 'styled-components'
import editIcon from '../../../assets/svg/actions/edit.svg'
import { MessageContext, MessageType } from '../../../state/context/MessageContext'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { useLocation, useParams } from 'react-router-dom'
import { NestedPageHeader } from '../../shared/NestedPageHeader'
import { ScrollTable, ScrollTableRowItem } from '../../shared/containers/ScrollTable'
import { VendorIndicator } from '../../shared/indicators/VendorIndicator'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { twLg } from '../../../design/constants'
import plusIcon from '../../../assets/svg/symbols/plus.svg'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { SearchInput } from '../../shared/filters/SearchInput'
import { CustomInput } from '../../shared/filters/FormComponents'
import { useBlurOnEnter } from '../../../hooks/useBlurOnEnter'
import { Currencies, CurrencyId, getCurrency } from '../../../utils/Currency'
import { Layout, SubLayoutContentWrapper } from '../../../layout/Layout'

export const TenantCostAnomalyRuleDetails = () => {
  const handleError = useErrorHandling()
  const location = useLocation()
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { setMessage } = useContext(MessageContext)
  const { ruleId } = useParams<{ ruleId: string }>()
  const [rule, setRule] = useState<TenantCostAnomalyRule>()
  const [projectOptions, setProjectOptions] = useState<GroupedOptions[]>([])
  const [serviceOptions, setServiceOptions] = useState<GroupedOptions[]>([])

  useEffect(() => {
    const cancelToken = createCancelToken()
    ruleId &&
      Promise.all([
        getTenantCostAnomalyRule(ruleId, createCancelToken().token).then(setRule),
        getCostAnomalyTargetOptions(cancelToken.token).then(data => {
          setProjectOptions(data.projects)
          setServiceOptions(data.services)
        })
      ]).catch(handleError)
  }, [handleError, createCancelToken, ruleId])

  if (!rule)
    return (
      <Layout type={'sub'}>
        <MissingDataNotification displayText={t('admin.alerts.costs.ruleNotFound')} />
      </Layout>
    )

  const editRule = (newRule: TenantCostAnomalyRule) => {
    const toast =
      newRule.name !== rule.name ||
      JSON.stringify(newRule.decrease) !== JSON.stringify(rule.decrease) ||
      JSON.stringify(newRule.increase) !== JSON.stringify(rule.increase) ||
      newRule.currency !== rule.currency ||
      newRule.emailAddresses.length !== rule.emailAddresses.length
    editTenantCostAnomalyRule(newRule.ruleId, newRule, createCancelToken().token)
      .then(resp => {
        setRule(resp)
        toast &&
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.alerts.costs.ruleDetails.editRuleSuccess')
          })
      })
      .catch(handleError)
  }

  return (
    <Layout type={'sub'}>
      <NestedPageHeader
        backButtonPath={location.pathname.split('/').slice(0, -1).join('/')}
        mainHeading={rule.name}
        subHeading={<GrayText>{`${t('admin.updated')} ${formatDate(rule.updatedAt)}`}</GrayText>}
      />
      <SubLayoutContentWrapper>
        <div className={'flex flex-col w-full divide-y divide-gray-500'}>
          <EditName rule={rule} editRule={editRule} />
          <EditEmails rule={rule} editRule={editRule} />
          <EditRuleLimits rule={rule} editRule={editRule} />
          <EditRuleTargets
            rule={rule}
            editRule={editRule}
            projectOptions={projectOptions}
            serviceOptions={serviceOptions}
          />
          <RuleTargetsList rule={rule} editRule={editRule} serviceOptions={serviceOptions} />
        </div>
      </SubLayoutContentWrapper>
    </Layout>
  )
}

interface RuleEditProps {
  rule: TenantCostAnomalyRule
  editRule: (rule: TenantCostAnomalyRule, toast?: boolean) => void
}

const EditName = ({ rule, editRule }: RuleEditProps) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState(rule.name)
  const [focused, setFocused] = useState(false)
  useBlurOnEnter('rule-name-input')

  return (
    <RuleDetailsBlock>
      <Heading2>{t('admin.alerts.costs.ruleDetails.nameHeading')}</Heading2>
      <div
        className={`flex gap-3 p-2 items-center max-w-124 border-b ${focused ? 'border-gray-300' : 'border-gray-400/60'}`}
      >
        <NameInput
          id={'rule-name-input'}
          type={'text'}
          value={inputValue}
          placeholder={t('admin.alerts.costs.namePlaceholder')}
          onChange={e => setInputValue(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={e => {
            setFocused(false)
            e.target.value !== rule.name && e.target.value.length > 0
              ? editRule(
                  {
                    ...rule,
                    name: e.target.value.trim()
                  },
                  true
                )
              : setInputValue(rule.name)
          }}
        />
        <CustomIcon path={editIcon} styles={`${focused ? 'bg-gray-50' : 'bg-gray-300'} w-4 h-4`} />
      </div>
    </RuleDetailsBlock>
  )
}

const NameInput = styled.input`
  ${tw`font-semibold bg-transparent text-gray-50 w-full outline-none`}
  ::placeholder {
    ${tw`text-gray-300 font-normal`}
  }
`

const EditEmails = ({ rule, editRule }: RuleEditProps) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const [newEmails, setNewEmails] = useState<string[]>([])
  useBlurOnEnter('rule-emails-input')

  const RecipientList = () => {
    return (
      <div>
        {rule.emailAddresses.map(email => (
          <div key={email} className={'flex items-center gap-3'}>
            <WhiteText className={'w-full max-w-124'}>{email}</WhiteText>
            <DeleteButton
              size={5}
              clickHandler={() => {
                editRule({
                  ...rule,
                  emailAddresses: rule.emailAddresses.filter(e => e !== email)
                })
              }}
              tooltipText={t('common.delete')}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <RuleDetailsBlock>
      <Heading2>{t('admin.alerts.costs.ruleDetails.emailsHeading')}</Heading2>
      <div className={'flex flex-col gap-8'}>
        {rule.emailAddresses.length > 0 ? (
          <RecipientList />
        ) : (
          <MissingDataNotification
            paddingY={1}
            size={5}
            justify={'start'}
            displayText={t('admin.alerts.costs.ruleDetails.noRecipients')}
          />
        )}
        <div className={'flex flex-col gap-1'}>
          <GrayText>{t('admin.alerts.costs.ruleDetails.emailsDescription')}</GrayText>
          <div className={'flex items-center gap-3'}>
            <CustomInput
              id={'rule-emails-input'}
              className={'max-w-124'}
              placeholder={t('admin.alerts.costs.emailsPlaceholder')}
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value)
                setNewEmails(
                  Array.from(
                    new Set(
                      e.target.value
                        .split(',')
                        .map(email => email.trim())
                        .filter(email => emailRegExp.test(email))
                        .filter(email => !rule.emailAddresses.includes(email))
                    )
                  )
                )
              }}
              onBlur={() => {
                if (newEmails.length > 0) {
                  editRule(
                    {
                      ...rule,
                      emailAddresses: [...newEmails, ...rule.emailAddresses]
                    },
                    true
                  )
                  setNewEmails([])
                  setInputValue('')
                }
              }}
            />
            <Button
              buttonStyle={ButtonStyle.GHOST}
              size={ButtonSize.XSMALL}
              type={ButtonType.ICON}
              value={
                <CustomIcon path={plusIcon} styles={`w-5 h-5 ${newEmails.length > 0 ? 'bg-gray-50' : 'bg-gray-200'}`} />
              }
              disabled={newEmails.length === 0}
            />
          </div>
        </div>
      </div>
    </RuleDetailsBlock>
  )
}

const EditRuleLimits = ({ rule, editRule }: RuleEditProps) => {
  const { t } = useTranslation()
  const [decrease, setDecrease] = useState<CostAnomalyLimits>(rule.decrease)
  const [increase, setIncrease] = useState<CostAnomalyLimits>(rule.increase)
  const [monthlyBudget, setMonthlyBudget] = useState(rule.monthlyBudget)
  const [budgetAlertPercent, setBudgetAlertPercent] = useState(rule.budgetAlertPercent)

  useEffect(() => {
    setDecrease(rule.decrease)
    setIncrease(rule.increase)
    setMonthlyBudget(rule.monthlyBudget)
    setBudgetAlertPercent(rule.budgetAlertPercent)
  }, [rule])

  return (
    <RuleDetailsBlock>
      <Heading2>{t('admin.alerts.costs.ruleDetails.limitsHeading')}</Heading2>
      <LimitsWrapper>
        <CostAnomalyLimitInputs
          key={JSON.stringify(decrease)}
          type={ChangeType.DECREASING}
          limits={decrease}
          currency={rule.currency}
          onBlur={(value?: CostAnomalyLimits) => {
            value &&
              value !== rule.decrease &&
              editRule({
                ...rule,
                decrease: value ?? decrease
              })
          }}
        />
        <CostAnomalyLimitInputs
          key={JSON.stringify(increase)}
          type={ChangeType.INCREASING}
          limits={increase}
          currency={rule.currency}
          onBlur={(value?: CostAnomalyLimits) => {
            value &&
              value !== rule.increase &&
              editRule({
                ...rule,
                increase: value ?? increase
              })
          }}
        />
        <CostAnomalyBudgetInputs
          key={JSON.stringify(monthlyBudget) + JSON.stringify(budgetAlertPercent)}
          monthlyBudget={monthlyBudget}
          budgetAlertPercent={budgetAlertPercent}
          currency={rule.currency}
          showInitially={!!(rule.monthlyBudget && rule.budgetAlertPercent)}
          onBudgetBlur={value => {
            value !== rule.monthlyBudget &&
              editRule({
                ...rule,
                monthlyBudget: value,
                budgetAlertPercent: !value ? null : rule.budgetAlertPercent || 75
              })
            setMonthlyBudget(value)
          }}
          onPercentBlur={value => {
            value !== rule.budgetAlertPercent &&
              editRule({
                ...rule,
                budgetAlertPercent: value
              })
            setBudgetAlertPercent(value)
          }}
          onClear={() =>
            editRule({
              ...rule,
              monthlyBudget: null,
              budgetAlertPercent: null
            })
          }
        />
      </LimitsWrapper>
      <div className={'flex flex-col gap-2'}>
        <WhiteText className={'first-letter:capitalize text-80 font-semibold'}>
          {t('admin.alerts.costs.ruleDetails.changeCurrency')}
        </WhiteText>
        <FilterSelect
          type={FilterType.CURRENCY}
          value={[{ value: rule.currency, label: getCurrency(rule.currency).symbolLong }]}
          className={'w-fit min-w-44'}
          options={Currencies.map(currencyId => ({ value: currencyId, label: getCurrency(currencyId).symbolLong }))}
          changeUserCurrency={false}
          onChange={selected => editRule({ ...rule, currency: (selected as FilterOption).value as CurrencyId })}
        />
      </div>
    </RuleDetailsBlock>
  )
}

const LimitsWrapper = styled.div`
  ${tw`flex flex-wrap gap-10`}
  & > div {
    ${tw`flex-1 min-w-80 max-w-120`}
  }
`

interface EditRuleTargetsProps {
  rule: TenantCostAnomalyRule
  editRule: (rule: TenantCostAnomalyRule) => void
  projectOptions: GroupedOptions[]
  serviceOptions: GroupedOptions[]
}

const EditRuleTargets = ({ rule, editRule, projectOptions, serviceOptions }: EditRuleTargetsProps) => {
  const { t } = useTranslation()
  const awsServices = getDistinctServiceOptions(
    Vendor.AWS,
    rule.targets.filter(t => t.vendor === Vendor.AWS)
  )
  const azureServices = getDistinctServiceOptions(
    Vendor.AZURE,
    rule.targets.filter(t => t.vendor === Vendor.AZURE)
  )
  const gcpServices = getDistinctServiceOptions(
    Vendor.GCP,
    rule.targets.filter(t => t.vendor === Vendor.GCP)
  )
  const sharedServices = [...awsServices, ...azureServices, ...gcpServices]

  return (
    <RuleDetailsBlock>
      <Heading2>{t('admin.alerts.costs.ruleDetails.targetsHeading')}</Heading2>
      <div className={'flex flex-wrap gap-8 2xl:gap-16'}>
        <div className={'w-full max-w-124'}>
          <GrayText className={'pb-2'}>{t('admin.alerts.costs.ruleDetails.projectsDescription')}</GrayText>
          <CostAnomalyRuleTargetMenu
            type={FilterType.PROJECTS}
            options={projectOptions}
            value={rule.targets}
            className={'max-w-124'}
            onChange={selected => {
              editRule({
                ...rule,
                targets: selected.map(
                  selectedTarget =>
                    ({
                      vendor: selectedTarget.vendor,
                      label: selectedTarget.label,
                      value: selectedTarget.value,
                      costs:
                        rule.targets.find(t => t.vendor === selectedTarget.vendor && t.value === selectedTarget.value)
                          ?.costs || [],
                      services: sharedServices.filter(s => s.vendor === selectedTarget.vendor)
                    }) as CostAnomalyProject
                )
              })
            }}
          />
        </div>
        <div>
          <GrayText className={'pb-2'}>{t('admin.alerts.costs.ruleDetails.sharedServicesDescription')}</GrayText>
          <CostAnomalyRuleTargetMenu
            type={FilterType.SERVICES}
            options={serviceOptions.filter(o => rule.targets.some(t => t.vendor === o.label))}
            value={sharedServices}
            className={'w-full max-w-124'}
            onChange={selected => {
              editRule({
                ...rule,
                targets: rule.targets.map(
                  t =>
                    ({
                      vendor: t.vendor,
                      label: t.label,
                      value: t.value,
                      costs: t.costs,
                      services: [
                        ...selected.filter(s => s.vendor === t.vendor),
                        ...t.services.filter(s => !sharedServices.find(ss => ss.value === s.value))
                      ]
                    }) as CostAnomalyProject
                )
              })
            }}
          />
          <GrayText className={'text-90 pt-2'}>{t('admin.alerts.costs.ruleDetails.projectServicesTip')}</GrayText>
        </div>
      </div>
    </RuleDetailsBlock>
  )
}

interface RuleTargetsListProps {
  rule: TenantCostAnomalyRule
  editRule: (rule: TenantCostAnomalyRule) => void
  serviceOptions: GroupedOptions[]
}

const RuleTargetsList = ({ rule, editRule, serviceOptions }: RuleTargetsListProps) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [filteredTargets, setFilteredTargets] = useState<CostAnomalyProject[]>(rule.targets)
  const [editingServicesFor, setEditingServicesFor] = useState<false | string>(false)

  useEffect(() => {
    if (searchText === '') {
      setFilteredTargets(rule.targets)
    } else {
      setFilteredTargets(
        rule.targets.filter(
          t =>
            t.label.toLowerCase().includes(searchText.toLowerCase()) ||
            t.value.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    }
  }, [searchText, rule.targets])

  const totalCosts = rule.targets
    .flatMap(t => t.costs.filter(c => new Date(c.date).getMonth() === getLastMonth().getMonth()))
    .reduce((acc, cost) => acc + cost.amount, 0)

  const projectPhrase = (plural: boolean) =>
    [
      (!rule.targets.length || rule.targets.some(t => t.vendor === Vendor.AWS)) &&
        (plural ? t('vendors.AWS.projectPhrase_other') : t('vendors.AWS.projectPhrase_one')),
      (!rule.targets.length || rule.targets.some(t => t.vendor === Vendor.AZURE)) &&
        (plural ? t('vendors.AZURE.projectPhrase_other') : t('vendors.AZURE.projectPhrase_one')),
      (!rule.targets.length || rule.targets.some(t => t.vendor === Vendor.GCP)) &&
        (plural ? t('vendors.GCP.projectPhrase_other') : t('vendors.GCP.projectPhrase_one'))
    ]
      .filter(Boolean)
      .join(' / ')

  const titles = [projectPhrase(false), t('common.service_other'), t('admin.alerts.costs.ruleDetails.allocation')]

  return (
    <RuleDetailsBlock className={'max-w-300'}>
      <Heading2>
        {t('admin.alerts.costs.ruleDetails.targetListHeading', { projectPhrase: projectPhrase(true) })}
      </Heading2>
      <div className={'flex flex-wrap gap-8 2xl:gap-16'}>
        <SearchInput
          placeholder={t('admin.alerts.costs.ruleDetails.searchTargets')}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <GrayText className={'text-90'}>
          {t('admin.alerts.costs.ruleDetails.lastMonthCosts')}
          <WhiteText className={'pl-2 inline-flex tracking-wide font-black'}>
            {`${formatNumber(totalCosts, 0)} ${getCurrency(rule.currency).symbol}`}
          </WhiteText>
        </GrayText>
      </div>
      <ScrollTable
        smallScreenTitle={t('admin.alerts.costs.ruleDetails.targets')}
        sortable={false}
        customColumns={'repeat(2, 1fr) 150px minmax(100px, auto)'}
        titles={titles}
        styles={`max-h-[40vh] ${editingServicesFor && 'min-h-[380px]'}`}
        rows={
          filteredTargets.length > 0
            ? filteredTargets.map(target => (
                <TargetRow
                  key={JSON.stringify(target)}
                  titles={titles}
                  target={target}
                  rule={rule}
                  editRule={editRule}
                  serviceOptions={serviceOptions}
                  editingServices={editingServicesFor}
                  setEditingServices={setEditingServicesFor}
                />
              ))
            : [
                <MissingDataNotification
                  paddingY={4}
                  justify={'start'}
                  displayText={t('admin.alerts.costs.ruleDetails.noTargets')}
                />
              ]
        }
      />
    </RuleDetailsBlock>
  )
}

interface TargetRowProps {
  titles: string[]
  target: CostAnomalyProject
  rule: TenantCostAnomalyRule
  editRule: (rule: TenantCostAnomalyRule) => void
  serviceOptions: GroupedOptions[]
  editingServices: false | string
  setEditingServices: (project: false | string) => void
}

const TargetRow = ({
  titles,
  target,
  rule,
  editRule,
  serviceOptions,
  editingServices,
  setEditingServices
}: TargetRowProps) => {
  const { t } = useTranslation()
  const screenSize = useScreenSize()
  const menuRef = useRef<HTMLDivElement | null>(null)
  const allocationRef = useRef<HTMLDivElement | null>(null)
  const [editingAllocation, setEditingAllocation] = useState<boolean>(false)
  const [currPercent, setCurrPercent] = useState(target.allocationPercent)
  useOnClickOutside(menuRef, () => setEditingServices(false))
  useOnClickOutside(allocationRef, () => !currPercent && setEditingAllocation(false))

  return (
    <>
      <ScrollTableRowItem>
        <GrayText hideOnWideScreen={true} className={'w-full'}>
          {t(`vendors.${target.vendor}.projectPhrase_one`)}
        </GrayText>
        <div className={'flex gap-4 w-full items-center justify-end lg:justify-start'}>
          {screenSize.width > twLg && <VendorIndicator type={'icon'} vendor={target.vendor} padding={1} size={15} />}
          <div>
            <WhiteText className={'flex gap-2 items-center justify-end lg:justify-start'}>
              {screenSize.width <= twLg && (
                <VendorIndicator type={'icon'} vendor={target.vendor} padding={1} size={5} />
              )}
              {target.label}
            </WhiteText>
            <GrayText className={'text-80 text-right lg:text-left'}>{target.value}</GrayText>
          </div>
        </div>
      </ScrollTableRowItem>
      <ScrollTableRowItem>
        <GrayText hideOnWideScreen={true} className={'w-full max-w-max'}>
          {titles[1]}
        </GrayText>
        {target.services.length || editingServices === target.value ? (
          <div className={'flex w-full justify-end'} ref={menuRef}>
            <CostAnomalyRuleTargetMenu
              useHeader={false}
              type={FilterType.SERVICES}
              menuOpen={target.services.length === 0 && editingServices ? true : undefined}
              options={serviceOptions.filter(o => o.label === target.vendor)}
              value={target.services}
              onChange={selected => {
                editRule({
                  ...rule,
                  targets:
                    rule.targets.map(t =>
                      t.vendor === target.vendor && t.value === target.value ? { ...t, services: selected } : t
                    ) || []
                })
              }}
            />
          </div>
        ) : (
          <TargetRowAction
            action={() => setEditingServices(target.value)}
            iconPath={plusIcon}
            label={t('admin.alerts.costs.ruleDetails.specifyServices')}
          />
        )}
      </ScrollTableRowItem>

      <ScrollTableRowItem>
        <GrayText hideOnWideScreen={true} className={'w-full max-w-max'}>
          {titles[2]}
        </GrayText>
        {target.allocationPercent || editingAllocation ? (
          <div ref={allocationRef}>
            <NumberInput
              clearable={true}
              type={'+'}
              min={1}
              max={100}
              autoFocus={editingAllocation}
              value={target.allocationPercent}
              onChange={value => setCurrPercent(value ? value : null)}
              onBlur={value => {
                const inputValue = !value ? null : value
                inputValue !== target.allocationPercent &&
                  editRule({
                    ...rule,
                    targets: rule.targets.map(t =>
                      t.vendor === target.vendor && t.value === target.value
                        ? {
                            ...t,
                            allocationPercent: inputValue
                          }
                        : t
                    )
                  })
              }}
            />
          </div>
        ) : (
          <TargetRowAction
            action={() => setEditingAllocation(true)}
            iconPath={plusIcon}
            label={t('admin.alerts.costs.ruleDetails.allocatePercent')}
          />
        )}
      </ScrollTableRowItem>
      <ScrollTableRowItem>
        <div className={'ml-auto'}>
          <DeleteButton
            size={5}
            clickHandler={() => {
              editRule({
                ...rule,
                targets: rule.targets.filter(t => t.vendor !== target.vendor || t.value !== target.value)
              })
            }}
            tooltipText={t('common.delete')}
          />
        </div>
      </ScrollTableRowItem>
    </>
  )
}

interface TargetRowActionProps {
  label: string
  action: () => void
  iconPath: string
}

const TargetRowAction = ({ label, action, iconPath }: TargetRowActionProps) => {
  return (
    <div
      className={
        'group flex gap-3 items-center py-1 cursor-pointer text-90 text-gray-200 transition-all ease-in-out duration-200 hover:scale-102 hover:text-gray-50'
      }
      onClick={() => action()}
    >
      <CustomIcon path={iconPath} styles={'w-4 h-4 bg-gray-200 group-hover:bg-gray-50'} />
      {label}
    </div>
  )
}

const RuleDetailsBlock = styled.div`
  ${tw`flex flex-col w-full flex flex-col gap-8 py-10 first:pt-0 last:pb-0`}
`

const getDistinctServiceOptions = (vendor: Vendor, targets: CostAnomalyProject[]): FilterOption[] => {
  const services = targets
    .flatMap(t => t.services)
    .filter(s => targets.every(t => t.services.some(ts => ts.value === s.value)))
  return Array.from(new Set(services.map(s => s.value))).map(
    s =>
      ({
        value: s,
        label: s,
        vendor: vendor
      }) as FilterOption
  )
}
