import styled from 'styled-components'
import tw from 'twin.macro'
import { Vendor } from '../../../utils/vendors'
import aws from '../../../assets/svg/vendors/aws-shadow.svg'
import azure from '../../../assets/svg/vendors/azure-shadow.svg'
import gcp from '../../../assets/svg/vendors/gcp-shadow.svg'

export enum VendorIndicatorState {
  ACTIVE,
  INACTIVE,
  DISABLED
}

type VendorIndicatorProps = {
  vendor: Vendor
  onClick?: () => void
  onHover?: () => void
} & (
  | {
      type: 'plain'
      size?: 2 | 3
      state?: never
      padding?: never
    }
  | {
      type: 'icon'
      size?: number
      padding?: number
      state?: VendorIndicatorState
    }
)

export const VendorIndicator = ({
  vendor,
  type,
  state = VendorIndicatorState.ACTIVE,
  size,
  padding,
  onClick,
  onHover
}: VendorIndicatorProps) => {
  if (type === 'plain')
    return (
      <PlainIndicator
        vendor={vendor}
        size={size === 2 ? 2 : 3}
        onClick={() => onClick && onClick()}
        onMouseOver={() => onHover && onHover()}
      />
    )

  const path = vendor === Vendor.AWS ? aws : vendor === Vendor.AZURE ? azure : vendor === Vendor.GCP ? gcp : undefined
  return (
    <IndicatorBase
      vendor={vendor}
      state={state}
      padding={padding || 2}
      onClick={onClick}
      className={onClick && 'cursor-pointer'}
    >
      <img alt={vendor} src={path} width={size || 20} height={size || 20} />
    </IndicatorBase>
  )
}

interface PlainIndicatorProps {
  vendor: Vendor
  size: 2 | 3
}

const PlainIndicator = styled.div<PlainIndicatorProps>`
  ${tw`rounded-full`}
  ${({ size }) => (size === 2 ? tw`min-w-2 h-2` : tw`min-w-3 h-3`)}
  ${({ vendor }) =>
    vendor === Vendor.AWS ? tw`bg-brand-aws` : vendor === Vendor.AZURE ? tw`bg-brand-azure` : tw`bg-brand-gcp`}
`

interface BaseProps {
  vendor: Vendor
  state: VendorIndicatorState
  padding: number
}

const IndicatorBase = styled.div<BaseProps>`
  ${tw`flex items-center bg-gray-300 w-max h-fit rounded-full transition-all ease-in-out duration-200`}
  ${({ vendor }) =>
    vendor === Vendor.AWS
      ? tw`bg-brand-aws`
      : vendor === Vendor.AZURE
        ? tw`bg-brand-azure`
        : vendor === Vendor.GCP
          ? tw`bg-brand-gcp`
          : tw`hidden`}
  ${({ state }) =>
    state === VendorIndicatorState.DISABLED
      ? tw`bg-gray-500`
      : state === VendorIndicatorState.INACTIVE
        ? tw`bg-opacity-20`
        : tw`bg-opacity-100`}
  padding: ${({ padding }) => padding / 4}rem;

  img {
    opacity: ${({ state }) => (state !== VendorIndicatorState.ACTIVE ? 0.5 : 1)};
  }
`
