import React from 'react'
import { useTranslation } from 'react-i18next'
import { IndicatorWrapper } from '../shared/indicators/SeverityLevelIndicator'

export enum ComplianceStatus {
  FAILED = 'FAILED',
  NonCompliant = 'NonCompliant',
  ACTIVE = 'ACTIVE',
  PASSED = 'PASSED',
  Compliant = 'Compliant',
  INACTIVE = 'INACTIVE'
}

export interface StateIndicatorProps {
  status: ComplianceStatus
}

const passedStatus = [ComplianceStatus.PASSED, ComplianceStatus.Compliant, ComplianceStatus.INACTIVE]

const failedStatus = [ComplianceStatus.FAILED, ComplianceStatus.NonCompliant, ComplianceStatus.ACTIVE]

export const ComplianceStatusIndicator = ({ status }: StateIndicatorProps) => {
  const { t } = useTranslation()
  const color = failedStatus.includes(status)
    ? { bg: 'bg-error-500/50', text: 'text-primary-500', icon: 'bg-primary-500' }
    : passedStatus.includes(status)
      ? { bg: 'bg-success-500/40', text: 'text-tertiary-200', icon: 'bg-tertiary-200' }
      : { bg: 'bg-gray-300/40', text: 'text-gray-200', icon: 'bg-gray-200' }

  return (
    <IndicatorWrapper className={`${color.bg} ${color.text}`}>
      {passedStatus.includes(status) ? t('compliance.status.pass') : t('compliance.status.fail')}
    </IndicatorWrapper>
  )
}
