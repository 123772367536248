import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader,
  IntegrationTimestampsSection
} from '../Shared'
import { AwsAccountType } from './AwsIntegrationDetails'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AwsIntegrationData } from '../../../../api/admin/integrations/aws'
import { formatDate } from '../../../../utils/formats'
import { AdminTabContent } from '../../adminStyles'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { SpotterDocumentationLink } from '../../../login/Shared'
import { CapitalizedText } from '../../../shared/TextComponents'

interface AwsIntegrationTimeBlocksProps {
  selectedIntegration: AwsIntegrationData
}

export const AwsIntegrationTimestamps = ({ selectedIntegration }: AwsIntegrationTimeBlocksProps) => {
  return (
    <>
      <MasterAccountSection selectedIntegration={selectedIntegration} />
      <SecurityAccountSection selectedIntegration={selectedIntegration} />
      <ConfigAccountSection selectedIntegration={selectedIntegration} />
    </>
  )
}

interface AccountSectionProps {
  selectedIntegration: AwsIntegrationData
}

const MasterAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  const errorSolutions = []
  integrationStates.aws?.some(
    i => i.masterAccount === selectedIntegration.master.accountId && !i.lastOrganizationIntegrationAt
  ) &&
    errorSolutions.push(
      <Trans
        i18nKey='admin.integrations.aws.missingOrganizationData'
        components={{
          CustomLink: <SpotterDocumentationLink path={'aws-access-management'} />
        }}
      />
    )
  integrationStates.aws?.some(
    i => i.masterAccount === selectedIntegration.master.accountId && !i.lastUsageIntegrationAt
  ) && errorSolutions.push(<CapitalizedText>{t('admin.integrations.aws.missingUsageData')}</CapitalizedText>)

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.aws.account', { type: AwsAccountType.MASTER })} />
          <IntegrationDetailRowsWrapper
            hasActions={false}
            detailRows={
              <>
                <IntegrationDetailRow
                  label={t('admin.integrations.organization')}
                  value={formatDate(selectedIntegration.master.lastIntegrations?.organization, true, true, true)}
                  error={integrationStates.aws?.some(
                    i => i.masterAccount === selectedIntegration.master.accountId && !i.lastOrganizationIntegrationAt
                  )}
                />
                <IntegrationDetailRow
                  label={t('sideNav.costs')}
                  value={formatDate(selectedIntegration.master.lastIntegrations?.costs, true, true, true)}
                  error={integrationStates.aws?.some(
                    i => i.masterAccount === selectedIntegration.master.accountId && !i.lastBillingIntegrationAt
                  )}
                />
                <IntegrationDetailRow
                  label={t('sideNav.optimization')}
                  value={formatDate(selectedIntegration.master.lastIntegrations?.optimization, true, true, true)}
                  error={integrationStates.aws?.some(
                    i => i.masterAccount === selectedIntegration.master.accountId && !i.lastRecommendationIntegrationAt
                  )}
                />
                <IntegrationDetailRow
                  label={t('sideNav.sustainability')}
                  value={formatDate(selectedIntegration.master.lastIntegrations?.sustainability, true, true, true)}
                  error={integrationStates.aws?.some(
                    i => i.masterAccount === selectedIntegration.master.accountId && !i.lastUsageIntegrationAt
                  )}
                />
              </>
            }
          />
        </AdminTabContent>
      }
      errorSolutions={errorSolutions}
    />
  )
}

const SecurityAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  const [collapsedSecurityAccounts, setCollapsedSecurityAccounts] = useState<number[]>([])

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.aws.account', { type: AwsAccountType.SECURITY })} />
          {selectedIntegration.security.map((account, index) => (
            <IntegrationDetailRowsWrapper
              key={index}
              hasActions={false}
              detailRows={
                <>
                  <IntegrationDetailRow
                    label={t('sideNav.compliance')}
                    value={formatDate(account.lastIntegrationAt, true, true, true)}
                    error={!account.lastIntegrationAt}
                  />
                  <IntegrationDetailRow
                    label={t('admin.integrations.accountId')}
                    isCollapsed={collapsedSecurityAccounts.includes(index)}
                    collapseHandler={() =>
                      !collapsedSecurityAccounts.includes(index)
                        ? setCollapsedSecurityAccounts([...collapsedSecurityAccounts, index])
                        : setCollapsedSecurityAccounts(collapsedSecurityAccounts.filter(item => item !== index))
                    }
                    value={account.accountId}
                  />
                  {collapsedSecurityAccounts.includes(index) && (
                    <>
                      <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
                      <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                    </>
                  )}
                </>
              }
            />
          ))}
        </AdminTabContent>
      }
      errorSolutions={
        integrationStates.aws?.some(
          i =>
            i.masterAccount === selectedIntegration.master.accountId &&
            selectedIntegration.security.some(sa => sa.accountId === i.securityAccount) &&
            !i.lastComplianceIntegrationAt
        ) && (
          <Trans
            i18nKey='admin.integrations.aws.missingComplianceData'
            components={{
              CustomLink: <SpotterDocumentationLink path={'aws-access-management'} />
            }}
          />
        )
      }
    />
  )
}

const ConfigAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  const [collapsedConfigAccounts, setCollapsedConfigAccounts] = useState<number[]>([])

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.aws.account', { type: AwsAccountType.CONFIG })} />
          {selectedIntegration.config.map((account, index) => (
            <IntegrationDetailRowsWrapper
              key={index}
              hasActions={false}
              detailRows={
                <>
                  <IntegrationDetailRow
                    label={t('sideNav.infra')}
                    value={formatDate(account.lastIntegrationAt, true, true, true)}
                    error={!account.lastIntegrationAt}
                  />
                  <IntegrationDetailRow
                    label={t('admin.integrations.accountId')}
                    isCollapsed={collapsedConfigAccounts.includes(index)}
                    collapseHandler={() =>
                      !collapsedConfigAccounts.includes(index)
                        ? setCollapsedConfigAccounts([...collapsedConfigAccounts, index])
                        : setCollapsedConfigAccounts(collapsedConfigAccounts.filter(item => item !== index))
                    }
                    value={account.accountId}
                  />
                  {collapsedConfigAccounts.includes(index) && (
                    <>
                      <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
                      <IntegrationDetailRow
                        label={t('admin.integrations.aws.aggregatorName')}
                        value={account.aggregatorName}
                      />
                      <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                    </>
                  )}
                </>
              }
            />
          ))}
        </AdminTabContent>
      }
      errorSolutions={
        integrationStates.aws?.some(
          i =>
            i.masterAccount === selectedIntegration.master.accountId &&
            selectedIntegration.config.some(account => account.accountId === i.configAccount) &&
            (!i.lastResourceIntegrationAt || !i.lastOrganizationIntegrationAt)
        ) && (
          <Trans
            i18nKey='admin.integrations.aws.missingOrganizationData'
            components={{
              CustomLink: <SpotterDocumentationLink path={'aws-access-management'} />
            }}
          />
        )
      }
    />
  )
}
