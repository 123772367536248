import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCancelToken } from '../../../../api/client'
import { useErrorHandling } from '../../../../hooks/handleError'
import {
  createGcpIntegration,
  GcpIntegration,
  GcpIntegrationData,
  GcpIntegrationRequest,
  getGcpIntegrations,
  recoverGcpIntegration
} from '../../../../api/admin/integrations/gcp'
import { ScrollTableRowItem } from '../../../shared/containers/ScrollTable'
import { formatDate } from '../../../../utils/formats'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'
import { ModalContext } from '../../../../state/context/ModalContext'
import { ModalActions } from '../../../shared/modal/Modal'
import { useForm, UseFormRegister } from 'react-hook-form'
import {
  DetailedIntegrationModal,
  IntegrationModalHighlight,
  IntegrationsModalBody,
  IntegrationsModalInputs,
  IntegrationsModalTitle
} from '../Shared'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { IntegrationsLayout } from '../IntegrationsLayout'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CapitalizedText, ErrorText } from '../../../shared/TextComponents'
import { firstLogin } from '../../../../state/storage'
import { StepMeter } from '../../../shared/StepMeter'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { Alert } from '../../../shared/indicators/Alert'
import { hasIntegrationErrors } from '../../../../api/settings/profile'

interface GcpIntegrationsProps {
  integrations: GcpIntegration[]
  setIntegrations: (integrations: GcpIntegration[]) => void
}

export const GcpIntegrations = ({ integrations, setIntegrations }: GcpIntegrationsProps) => {
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const { integrationStates } = useContext(UserInfoContext)
  const navigate = useNavigate()
  const handleError = useErrorHandling()
  const [searchParams] = useSearchParams()
  const isFirst = Boolean(searchParams.get('first'))
  const [loading, setLoading] = useState<boolean>(true)
  const [step, setStep] = useState(isFirst ? 1 : 0)
  const [stepTitle, setStepTitle] = useState<string>(t('admin.integrations.gcp.organizationDetails'))

  useEffect(() => {
    setStepTitle(
      step === 1 ? t('admin.integrations.gcp.organizationDetails') : t('admin.integrations.gcp.accountDetails')
    )

    if (isFirst || step >= 1) {
      openAddModal()
    } else {
      setLoading(true)
      getGcpIntegrations(createCancelToken().token)
        .then(setIntegrations)
        .catch(handleError)
        .finally(() => setLoading(false))
    }

    return () => setLoading(false)
  }, [step, stepTitle, createCancelToken, handleError])

  const openAddModal = () => {
    const recoverAction = (organizationId: string) => {
      recoverGcpIntegration(organizationId)
        .then(() => {
          setModal(null)
          navigate(organizationId)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.gcp.orgRecoverSuccessToast')
          })
        })
        .catch(handleError)
    }

    const createAction = (request: GcpIntegrationRequest) => {
      const cancelToken = createCancelToken()
      createGcpIntegration(request, cancelToken.token)
        .then(resp => {
          if (resp.deletedAt) {
            setModal({
              header: t('admin.integrations.gcp.orgRecoveryHeader'),
              body: (
                <DetailedIntegrationModal
                  description={t('admin.integrations.gcp.orgRecoveryMessage')}
                  detailRows={[
                    { label: t('admin.integrations.gcp.organizationId'), value: resp.organizationId },
                    { label: t('admin.integrations.name'), value: resp.name }
                  ]}
                  action={() => recoverAction(request.organizationId)}
                  actionLabel={t('admin.integrations.recover')}
                />
              )
            })
          } else {
            setIntegrations([resp, ...integrations])
            setModal(null)
            setMessage({
              type: MessageType.SUCCESS,
              message: t('admin.integrations.gcp.addSuccessToast')
            })
            !firstLogin() && navigate(resp.organizationId)
            isFirst && searchParams.delete('first')
          }
        })
        .catch(handleError)
    }

    setModal({
      header: <StepMeter currentStep={step} totalSteps={2} description={t('admin.integrations.gcp.createTitle')} />,
      returnAction: isFirst ? () => navigate(-1) : undefined,
      body: <GcpIntegrationsAddModal submitAction={createAction} step={step} setStep={setStep} title={stepTitle} />
    })
  }

  return (
    <IntegrationsLayout
      noData={!integrations.length}
      loading={loading}
      heading={t('admin.integrations.gcp.title')}
      headerActions={
        <Button
          clickHandler={() => {
            setStep(1)
            openAddModal()
          }}
          value={t('admin.integrations.addNew')}
        />
      }
      scrollTableProps={{
        smallScreenTitle: t('admin.integrations.integrations'),
        sortable: false,
        customColumns: 'repeat(4, 1fr) minmax(90px, auto)',
        titles: [
          t('admin.integrations.name'),
          t('admin.integrations.gcp.organizationId'),
          t('admin.integrations.lastIntegrationAt'),
          t('admin.integrations.status')
        ],
        rows: integrations.map(organization => (
          <>
            <ScrollTableRowItem>{organization.name}</ScrollTableRowItem>
            <ScrollTableRowItem>{organization.organizationId}</ScrollTableRowItem>
            <ScrollTableRowItem>{formatDate(organization.lastIntegrationAt, true, true, true)}</ScrollTableRowItem>
            <ScrollTableRowItem>
              {organization.deletedAt ? (
                <ErrorText>{t('common.status.removed')}</ErrorText>
              ) : (
                <CapitalizedText className={'text-gray-100/90'}>{t('common.status.active')}</CapitalizedText>
              )}
              {hasIntegrationErrors(
                integrationStates.gcp?.filter(i => i.organizationId === organization.organizationId) || null
              ) && <Alert />}
            </ScrollTableRowItem>
            <Button
              value={t('admin.integrations.viewDetails')}
              clickHandler={() => navigate(organization.organizationId)}
              buttonStyle={ButtonStyle.SECONDARY}
              size={ButtonSize.XSMALL}
            />
          </>
        ))
      }}
    />
  )
}

type AddModalProps = {
  submitAction: (request: GcpIntegrationRequest) => void
  selectedIntegration?: GcpIntegrationData
} & (
  | {
      accountOnly: true
      step?: never
      setStep?: never
      title?: never
    }
  | {
      accountOnly?: false
      step: number
      setStep: (step: number) => void
      title: string
    }
)

export const GcpIntegrationsAddModal = ({
  submitAction,
  selectedIntegration,
  step,
  setStep,
  title,
  accountOnly
}: AddModalProps) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = useForm<GcpIntegrationRequest>({
    defaultValues: {
      organizationId: selectedIntegration?.organizationId,
      name: selectedIntegration?.name,
      accountId: '',
      projectId: '',
      tableId: ''
    }
  })

  const onSubmit = (data: GcpIntegrationRequest) => {
    submitAction(data)
  }

  return (
    <form key={step} className={'max-w-116'}>
      <IntegrationsModalBody>
        {!accountOnly && <IntegrationsModalTitle>{title}</IntegrationsModalTitle>}
        <IntegrationModalHighlight type={'create'} path={'gcp-get-started-guide'} />
        {step === 1 ? <OrganizationModal register={register} /> : <BillingAccountModal register={register} />}
      </IntegrationsModalBody>
      <ModalActions>
        {step === 1 ? (
          <Button
            disabled={!isValid || isSubmitting}
            value={t('common.next')}
            size={ButtonSize.SMALL}
            type={ButtonType.FORM}
            buttonStyle={ButtonStyle.GHOST}
            clickHandler={event => {
              setStep(step + 1)
              event.preventDefault()
            }}
          />
        ) : (
          <>
            {setStep && (
              <Button
                disabled={isSubmitting}
                value={t('common.prev')}
                size={ButtonSize.SMALL}
                type={ButtonType.FORM}
                buttonStyle={ButtonStyle.GHOST}
                clickHandler={event => {
                  event.preventDefault()
                  setStep(step - 1)
                }}
              />
            )}
            <Button
              disabled={!isValid || isSubmitting}
              value={t('admin.integrations.addNew')}
              size={ButtonSize.SMALL}
              type={ButtonType.FORM}
              clickHandler={handleSubmit(onSubmit)}
            />
          </>
        )}
      </ModalActions>
    </form>
  )
}

interface ModalProps {
  register: UseFormRegister<GcpIntegrationRequest>
}

const OrganizationModal = ({ register }: ModalProps) => {
  const { t } = useTranslation()

  return (
    <IntegrationsModalInputs>
      <div>
        <CustomLabel>{t('admin.integrations.gcp.organizationId')} *</CustomLabel>
        <CustomInput
          type={'number'}
          {...register('organizationId', {
            required: true
          })}
          autoFocus={true}
        />
      </div>
      <div>
        <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
        <CustomInput
          {...register('name', {
            required: true,
            minLength: 1
          })}
        />
      </div>
    </IntegrationsModalInputs>
  )
}

export const BillingAccountModal = ({ register }: ModalProps) => {
  const { t } = useTranslation()
  return (
    <IntegrationsModalInputs>
      <div>
        <CustomLabel>{t('admin.integrations.gcp.billingAccountId')} *</CustomLabel>
        <CustomInput
          {...register('accountId', {
            required: true,
            validate: value => {
              const billingAccountRegExp = new RegExp('^[0-9a-fA-F]{6}-[0-9a-fA-F]{6}-[0-9a-fA-F]{6}$')
              return billingAccountRegExp.test(value)
            }
          })}
        />
      </div>
      <div>
        <CustomLabel>{t('admin.integrations.gcp.projectId')} *</CustomLabel>
        <CustomInput
          {...register('projectId', {
            required: true,
            minLength: 1
          })}
        />
      </div>
      <div>
        <CustomLabel>{t('admin.integrations.gcp.bigQueryTableId')} *</CustomLabel>
        <CustomInput
          {...register('tableId', {
            required: true,
            minLength: 1
          })}
        />
      </div>
    </IntegrationsModalInputs>
  )
}
