import React, { useContext, useEffect, useState } from 'react'
import { initI18n } from './utils/i18n'
import { useCancelToken } from './api/client'
import { UserInfoContext } from './state/context/UserInfoContext'
import { GlobalMessage } from './global-message/GlobalMessage'
import { FullScreenLoading } from './components/shared/Loading'
import SpotterRoutes from './layout/routes/SpotterRoutes'
import { getUserContextData } from './api/settings/profile'
import { featuresFlags } from './state/featuresFlags'
import { Notifications } from './components/notifications/Notifications'
import { useNavigate } from 'react-router-dom'
import { AuthInfo } from './api/auth'

initI18n('en')
const App = () => {
  const { createCancelToken } = useCancelToken()
  const navigate = useNavigate()
  const { authInfo, setAuthInfo, setUserSettings, setIntegrationStates, setTenants } = useContext(UserInfoContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)

    const storedAuthInfo = localStorage.getItem('authInfo')
    if (storedAuthInfo) {
      const parsedAuthInfo = parseAuthInfo(JSON.parse(storedAuthInfo))
      setAuthInfo(parsedAuthInfo)
      getUserContextData(cancelToken.token)
        .then(resp => {
          setAuthInfo(resp.authInfo)
          setUserSettings(resp.userSettings)
          setIntegrationStates(resp.integrationStates)
          setTenants(resp.tenants)
        })
        .catch(console.error)
        .finally(() => setLoading(false))
    } else {
      getUserContextData(cancelToken.token)
        .then(resp => {
          setAuthInfo(resp.authInfo)
          setUserSettings(resp.userSettings)
          setIntegrationStates(resp.integrationStates)
          setTenants(resp.tenants)
          !resp.authInfo.isLoggedIn && navigate('/login')
        })
        .catch(console.error)
        .finally(() => setLoading(false))
    }
  }, [createCancelToken, setAuthInfo, setIntegrationStates, setUserSettings, setTenants])

  if (loading) return <FullScreenLoading />

  return (
    <>
      <SpotterRoutes />
      {featuresFlags.notifications && authInfo.notificationsAccess && authInfo.isLoggedIn && <Notifications />}
      <GlobalMessage />
    </>
  )
}

const parseAuthInfo = (authInfo: AuthInfo) => {
  return {
    ...authInfo,
    site247Integration: authInfo.site247Integration ? new Date(authInfo.site247Integration) : undefined,
    freshIntegration: authInfo.freshIntegration ? new Date(authInfo.freshIntegration) : undefined,
    lastLoginAt: authInfo.lastLoginAt ? new Date(authInfo.lastLoginAt) : null
  }
}

export default App
