import React, { useContext, useEffect, useState } from 'react'
import { CostTotalSummary, getCostsTotalSummary, VendorCostSummary } from '../../api/costs'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { useTranslation } from 'react-i18next'
import { CountUp } from '../shared/CountUp'
import { formatMonthLong, roundNumber } from '../../utils/formats'
import { FilterOption } from '../shared/filters/FilterSelect'
import { DataContainer } from '../shared/containers/DataContainer'
import { Loading } from '../shared/Loading'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import styled from 'styled-components'
import tw from 'twin.macro'
import { CostChangeIndicator } from '../shared/indicators/CostChangeIndicator'
import { GradientText, GrayText, Heading, VendorName, WhiteText } from '../shared/TextComponents'
import { StackedBarChart } from '../shared/charts/StackedBarChart'
import { Vendor } from '../../utils/vendors'
import { VendorIndicator } from '../shared/indicators/VendorIndicator'
import { CurrencyId, getCurrency } from '../../utils/Currency'
import { UserInfoContext } from '../../state/context/UserInfoContext'

interface CurrentCostsProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedTags: FilterOption[]
  selectedCurrency: CurrencyId
}

export const CostsTotalSummary = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedTags,
  selectedCurrency
}: CurrentCostsProps) => {
  const { userSettings } = useContext(UserInfoContext)
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const [costData, setCostData] = useState<CostTotalSummary | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const { t } = useTranslation()

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getCostsTotalSummary(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedServices,
      selectedTags.map(tag => ({
        vendor: tag.vendor,
        tagKey: tag.label,
        tagValues: tag.nest?.map(value => value.value) ?? []
      })),
      selectedCurrency,
      cancelToken.token
    )
      .then(setCostData)
      .catch(handleError)
      .finally(() => setLoading(false))
    return () => {
      cancelToken.cancel()
      setLoading(false)
      setCostData(null)
    }
  }, [
    createCancelToken,
    handleError,
    selectedProjects,
    selectedResourceGroups,
    selectedServices,
    selectedTags,
    selectedCurrency,
    selectedVendors
  ])

  const month = formatMonthLong(new Date(), false)
  const minWidth =
    userSettings.visibleVendors.length === 3
      ? 'xl:min-w-80'
      : userSettings.visibleVendors.length === 2
        ? 'xl:min-w-95'
        : 'xl:min-w-full'

  if (loading || !costData || !userSettings.visibleVendors.length)
    return (
      <SummariesContainer userAccesses={userSettings.visibleVendors.length}>
        <DataContainer looseSpacing={true} flexCol={true}>
          <SummaryContentWrapper className={minWidth}>
            <Heading>{t('costs.summary.monthlyTotals', { month: month })}</Heading>
            {loading ? <Loading paddingY={'2rem'} /> : <MissingDataNotification justify={'center'} />}
          </SummaryContentWrapper>
        </DataContainer>
        <DataContainer looseSpacing={true} flexCol={true}>
          <SummaryContentWrapper>
            <Heading>{t('costs.summary.totalsByVendor')}</Heading>
            {loading ? <Loading paddingY={'2rem'} /> : <MissingDataNotification justify={'center'} />}
          </SummaryContentWrapper>
        </DataContainer>
      </SummariesContainer>
    )

  return (
    <SummariesContainer userAccesses={userSettings.visibleVendors.length}>
      <DataContainer looseSpacing={true} flexCol={true}>
        <SummaryContentWrapper className={minWidth}>
          <Heading>{t('costs.summary.monthlyTotals', { month: month })}</Heading>
          <TotalCosts costData={costData} />
        </SummaryContentWrapper>
      </DataContainer>
      <DataContainer looseSpacing={true} flexCol={true}>
        <SummaryContentWrapper>
          <Heading>{t('costs.summary.totalsByVendor')}</Heading>
          <VendorSummaries
            costData={costData.vendorSummaries.filter(v => userSettings.visibleVendors.includes(v.vendor))}
          />
        </SummaryContentWrapper>
      </DataContainer>
    </SummariesContainer>
  )
}

interface SummariesContainerProps {
  userAccesses: number
}

const SummariesContainer = styled.div<SummariesContainerProps>`
  ${tw`flex gap-5 flex-col xl:flex-row`}
  ${({ userAccesses }) => userAccesses === 1 && tw`grid grid-cols-1 xl:grid-cols-2`}
  & > div:first-child {
    ${tw`min-w-max`}
  }

  > div:last-child {
    ${tw`w-full`}
  }
`

const SummaryContentWrapper = styled.div`
  ${tw`flex flex-col h-full w-full gap-9`}
  > div:first-child {
    ${tw`w-full text-center xl:text-left`}
  }
`

interface TotalCostsProps {
  costData: CostTotalSummary
}

const TotalCosts = ({ costData }: TotalCostsProps) => {
  const { t } = useTranslation()
  return (
    <TotalAmounts>
      <TotalCostsSection
        mainId={'qa-costs-current-total-cost'}
        mainLabel={t('costs.summary.currentCosts')}
        mainValue={costData.currentMonth}
        larger={true}
        mainSubId={'qa-costs-costs-change'}
        mainSubLabel={t('costs.summary.vsLastMonth')}
        mainSubValue={costData.changePercent}
        subId={'qa-costs-last-month-total-cost'}
        subLabel={t('costs.lastMonth')}
        subValue={costData.lastMonth}
        currency={costData.currency}
      />
      <TotalCostsSection
        mainId={'qa-costs-total-forecast'}
        mainLabel={t('costs.summary.currentForecast')}
        mainValue={costData.currentForecast}
        subId={'qa-costs-initial-forecast'}
        subLabel={t('costs.summary.initForecast')}
        subValue={costData.initialForecast}
        mainSubId={'qa-costs-forecasted-change'}
        mainSubLabel={t('costs.summary.forecastedChange')}
        mainSubValue={costData.forecastPercent}
        currency={costData.currency}
      />
    </TotalAmounts>
  )
}

interface TotalCostsSectionProps {
  mainId: string
  mainLabel: string
  mainValue: number | null
  subId: string
  subLabel: string
  subValue: number | null
  larger?: boolean
  mainSubId: string
  mainSubLabel: string
  mainSubValue: number | null
  currency: CurrencyId
}

const TotalCostsSection = ({
  mainId,
  mainLabel,
  mainValue,
  mainSubId,
  mainSubValue,
  mainSubLabel,
  subId,
  subLabel,
  subValue,
  larger = false,
  currency
}: TotalCostsSectionProps) => {
  return (
    <div className={'flex flex-col w-full items-center justify-center gap-2'}>
      <GrayText className={'w-full text-center'}>{mainLabel}</GrayText>
      <GradientText className={`${larger ? 'text-350' : 'text-250'} font-black from-gray-50 to-gray-200 leading-none`}>
        {mainValue !== null ? <CountUp id={mainId} countTo={roundNumber(mainValue, 0)} /> : '-'}
        <span className={larger ? 'text-250' : 'text-175'}>{` ${getCurrency(currency).symbol}`}</span>
      </GradientText>
      <div className={'flex justify-center items-center gap-1.5'}>
        <CostChangeIndicator id={mainSubId} change={mainSubValue} counter={true} bold={true} />
        <GrayText lowercase={true}>{mainSubLabel}</GrayText>
      </div>
      <div className={'flex w-full justify-evenly items-center gap-6 max-w-75 self-center xl:self-start xl:max-w-full'}>
        <GrayText>{subLabel}</GrayText>
        <WhiteText className={'font-semibold'}>
          {subValue ? (
            <>
              <CountUp id={subId} countTo={roundNumber(subValue, 0)} /> {getCurrency(currency).symbol}
            </>
          ) : (
            '-'
          )}
        </WhiteText>
      </div>
    </div>
  )
}

const TotalAmounts = styled.div`
  ${tw`flex flex-col gap-12 w-full xl:min-w-80`}
`

interface VendorSummariesProps {
  costData: VendorCostSummary[]
}

const VendorSummaries = ({ costData }: VendorSummariesProps) => {
  const { t } = useTranslation()
  const barChartData = costData.map(entry => ({
    vendor: entry.vendor,
    value: entry.currentCosts ?? 0.0
  }))

  return (
    <VendorCostsContainer>
      <VendorSummariesWrapper colCount={costData.length}>
        {costData.map(item => (
          <div key={item.vendor} className={'flex flex-col w-full gap-8'}>
            <div className={'flex items-center gap-4 leading-tighter'}>
              <VendorIndicator type={'icon'} vendor={item.vendor} size={24} />
              <div>
                <VendorName vendor={item.vendor} blank={true} fontSize={'larger'}>
                  {item.vendor}
                </VendorName>
                <GrayText className={'text-90'}>
                  {`${item.projectCount} ${t(`vendors.${item.vendor}.projectPhrase`, {
                    count: item.projectCount
                  })}`}
                </GrayText>
              </div>
            </div>
            <div className={'flex flex-col gap-5'}>
              <div className={'flex flex-col gap-1'}>
                <VendorSummaryRow
                  id={`qa-costs-${item.vendor}-last-month-cost`}
                  label={t('costs.lastMonth')}
                  value={item.lastMonthCosts}
                  currency={item.currency}
                />
                <VendorSummaryRow
                  id={`qa-costs-${item.vendor}-current-month-cost`}
                  label={t('costs.currentMonth')}
                  value={item.currentCosts}
                  currency={item.currency}
                  size={'xl'}
                  black={true}
                />
              </div>

              <div className={'flex flex-col gap-1'}>
                <VendorSummaryRow
                  id={`qa-costs-${item.vendor}-initial-forecast`}
                  label={t('costs.summary.initForecast')}
                  value={item.initialForecast}
                  currency={item.currency}
                />
                <VendorSummaryRow
                  id={`qa-costs-${item.vendor}-current-forecast`}
                  label={t('costs.summary.currentForecast')}
                  value={item.currentForecast}
                  currency={item.currency}
                  size={'lg'}
                  black={true}
                />
                <Row>
                  <GrayText>{t('costs.summary.forecastedChange')}</GrayText>
                  <CostChangeIndicator
                    id={`qa-costs-${item.vendor}-forecasted-change`}
                    change={item.forecastPercent}
                    counter={true}
                    bold={true}
                  />
                </Row>
              </div>
            </div>
          </div>
        ))}
      </VendorSummariesWrapper>

      <StackedBarChart data={barChartData} size={'lg'} />
    </VendorCostsContainer>
  )
}

interface VendorSummariesContainerProps {
  colCount: number
}

const VendorSummariesWrapper = styled.div<VendorSummariesContainerProps>`
  ${tw`grid grid-cols-1 gap-12 transition-all ease-in-out px-1 2xl:gap-16 3xl:gap-24`};
  @media (min-width: 1024px) {
    grid-template-columns: repeat(${({ colCount }) => colCount}, minmax(0, 1fr));
  }
`

const VendorCostsContainer = styled.div`
  ${tw`flex flex-col w-full h-full gap-10`}
`

interface VendorSummaryRowProps {
  id: string
  label: string
  value: number | null
  currency: CurrencyId
  size?: 'lg' | 'xl'
  black?: boolean
}

const VendorSummaryRow = ({ id, label, value, currency, size, black }: VendorSummaryRowProps) => {
  return (
    <Row>
      <GrayText>{label}</GrayText>
      <GradientText
        className={`from-gray-50 to-gray-100 ${black ? 'font-black' : 'font-semibold'} ${size === 'xl' ? 'text-125 lg:text-150' : size === 'lg' ? 'text-112 lg:text-125' : 'text-100'}`}
      >
        {value !== null ? (
          <>
            <CountUp id={id} countTo={roundNumber(value, 0)} />
            {` ${getCurrency(currency).symbol}`}
          </>
        ) : (
          '-'
        )}
      </GradientText>
    </Row>
  )
}

const Row = styled.div`
  ${tw`flex w-full gap-4 min-w-max items-center justify-between border-b border-dotted border-gray-500 lg:border-none`}
`
