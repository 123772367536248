import { GrayText, WhiteText } from './TextComponents'
import { ChangeType } from '../../api/notifications'
import { CurrencyId, getCurrency } from '../../utils/Currency'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Trans, useTranslation } from 'react-i18next'
import { CostAnomalyLimits } from '../../api/admin/alerts'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { useBlurOnEnter } from '../../hooks/useBlurOnEnter'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { Button, ButtonSize, ButtonStyle, ButtonType } from './buttons/Button'
import { CustomIcon } from './CustomIcon'
import plusIcon from '../../assets/svg/symbols/plus.svg'
import { formatNumber } from '../../utils/formats'

interface LimitInputsProps {
  type: ChangeType
  limits: CostAnomalyLimits
  onChange?: (limits?: CostAnomalyLimits) => void
  onBlur: (limits?: CostAnomalyLimits) => void
  currency?: CurrencyId
}

export const CostAnomalyLimitInputs = ({ type, limits, onChange, onBlur, currency }: LimitInputsProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const inputType = type === ChangeType.DECREASING ? '-' : '+'

  return (
    <div className={'w-full sm:min-w-80'}>
      <WhiteText className={'first-letter:capitalize text-80 font-semibold'}>
        {inputType === '-' ? t('notifications.costAnomalies.decrease') : t('notifications.costAnomalies.increase')}
      </WhiteText>
      <CostAnomalyInputRow
        label={t('settings.notifications.changePercent')}
        value={limits.percent}
        onChange={value => onChange && onChange({ ...limits, percent: value ?? limits.percent })}
        onBlur={(value?: number) => onBlur({ ...limits, percent: value ?? limits.percent })}
        min={inputType === '-' ? -100 : 1}
        max={inputType === '-' ? -1 : 100}
        type={inputType}
        clearable={false}
      />
      <CostAnomalyInputRow
        label={t('settings.notifications.monthlyImpact', {
          currency: getCurrency(currency || userSettings.currency).symbol
        })}
        value={limits.monthlyImpact}
        onChange={value => onChange && onChange({ ...limits, monthlyImpact: value ?? limits.monthlyImpact })}
        onBlur={(value?: number) => onBlur({ ...limits, monthlyImpact: value ?? limits.monthlyImpact })}
        min={inputType === '-' ? -99999 : 1}
        max={inputType === '-' ? -1 : 99999}
        type={inputType}
        clearable={false}
      />
    </div>
  )
}

interface BudgetInputsProps {
  monthlyBudget: number | null
  budgetAlertPercent: number | null
  currency: CurrencyId
  onClear: () => void
  onBudgetChange?: (value: number | null) => void
  onBudgetBlur: (value: number | null) => void
  onPercentChange?: (value: number | null) => void
  onPercentBlur: (value: number | null) => void
  showInitially: boolean
  lastMonthCosts?: number | null
  showDescription?: boolean
}

export const CostAnomalyBudgetInputs = ({
  monthlyBudget,
  budgetAlertPercent,
  currency,
  onClear,
  onBudgetChange,
  onBudgetBlur,
  onPercentChange,
  onPercentBlur,
  showInitially,
  lastMonthCosts,
  showDescription = true
}: BudgetInputsProps) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const [percentFocused, setPercentFocused] = useState(false)
  const [budgetAlertThreshold, setBudgetAlertThreshold] = useState(
    monthlyBudget && budgetAlertPercent ? monthlyBudget * (budgetAlertPercent / 100) : null
  )
  const [inputsVisible, setInputsVisible] = useState(showInitially)

  useBlurOnEnter('monthly-budget-input')
  useBlurOnEnter('budget-threshold-percent-input')
  useOnClickOutside(containerRef, () => !budgetAlertThreshold && setInputsVisible(false))

  if (!inputsVisible)
    return (
      <div>
        <WhiteText className={'first-letter:capitalize text-80 font-semibold'}>
          {t('settings.notifications.monthlyBudget')}
        </WhiteText>
        <div className={'flex gap-5 items-center pt-1'}>
          <GrayText>{t('settings.notifications.monitorBudget')}</GrayText>
          <Button
            size={ButtonSize.XSMALL}
            type={ButtonType.ICON}
            clickHandler={() => setInputsVisible(true)}
            value={<CustomIcon path={plusIcon} styles={'w-5 h-5 bg-gray-50'} />}
            tooltipText={t('common.add')}
          />
        </div>
      </div>
    )

  return (
    <div ref={containerRef} className={'w-full'}>
      <div className={'w-full flex justify-between items-center'}>
        <WhiteText className={'first-letter:capitalize text-80 font-semibold'}>
          {t('settings.notifications.monthlyBudget')}
        </WhiteText>
        {inputsVisible && (
          <Button
            buttonStyle={ButtonStyle.GHOST}
            type={ButtonType.ICON}
            value={<CustomIcon path={plusIcon} styles={'w-5 h-5 bg-gray-50 rotate-45'} />}
            tooltipText={t('common.clear')}
            clickHandler={() => {
              onClear()
              setInputsVisible(false)
              setBudgetAlertThreshold(null)
            }}
          />
        )}
      </div>
      <div>
        <CostAnomalyInputRow
          id={'monthly-budget-input'}
          label={t('settings.notifications.budget', { currency: getCurrency(currency).symbol })}
          type={'+'}
          value={monthlyBudget || 0}
          min={1}
          max={1000000}
          clearable={true}
          onChange={value => {
            if (value && value > 0) {
              onBudgetChange && onBudgetChange(value)
              setBudgetAlertThreshold(value * ((budgetAlertPercent ?? 75) / 100))
            } else setBudgetAlertThreshold(null)
          }}
          onBlur={value => {
            if (value && value > 0) {
              value !== monthlyBudget && onBudgetBlur(value)
              !budgetAlertPercent && setBudgetAlertThreshold(value * 0.75)
            } else setBudgetAlertThreshold(null)
          }}
        />
        <CostAnomalyInputRow
          id={'budget-threshold-percent-input'}
          key={!percentFocused ? JSON.stringify(budgetAlertPercent) : undefined}
          label={t('settings.notifications.alertPercent')}
          value={budgetAlertPercent || 0}
          type={'+'}
          min={1}
          max={100}
          clearable={true}
          onFocus={() => setPercentFocused(true)}
          onChange={value => {
            value && onPercentChange && onPercentChange(value)
            value && value > 0 && setBudgetAlertThreshold(monthlyBudget ? (monthlyBudget * value) / 100 : null)
          }}
          onBlur={value => {
            value && value !== budgetAlertPercent && onPercentBlur(value)
            setPercentFocused(false)
          }}
        />
        {lastMonthCosts && (
          <GrayText className={'text-80 text-right'}>
            <Trans>
              {t('settings.notifications.lastMonthCost', {
                amount: formatNumber(lastMonthCosts, 0),
                currency: getCurrency(currency).symbol
              })}
            </Trans>
          </GrayText>
        )}
      </div>
      {budgetAlertThreshold && showDescription && (
        <GrayText className={'text-90 pt-2'}>
          <Trans>
            {t('settings.notifications.budgetThresholdDescription', {
              amount: formatNumber(budgetAlertThreshold, 0),
              currency: getCurrency(currency).symbol
            })}
          </Trans>
        </GrayText>
      )}
    </div>
  )
}

interface CostAnomalyInputRowProps {
  id?: string
  label: string
  value: number
  onFocus?: () => void
  onChange?: (value?: number) => void
  onBlur: (value?: number) => void
  min: number
  max: number
  type: '+' | '-'
  clearable: boolean
  disabled?: boolean
}

export const CostAnomalyInputRow = ({
  id,
  label,
  value,
  onFocus,
  onChange,
  onBlur,
  min,
  max,
  type,
  clearable,
  disabled
}: CostAnomalyInputRowProps) => {
  return (
    <Row>
      <GrayText>{label}</GrayText>
      <NumberInput
        id={id}
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        min={min}
        max={max}
        type={type}
        clearable={clearable}
        disabled={disabled}
      />
    </Row>
  )
}

const Row = styled.div`
  ${tw`flex w-full justify-between items-center gap-5 py-1 text-90`}
`

interface LimitInputProps {
  id?: string
  value: number | null
  disabled?: boolean
  autoFocus?: boolean
  onFocus?: () => void
  onChange?: (value?: number) => void
  onBlur: (value?: number) => void
  min: number
  max: number
  type: '+' | '-'
  clearable: boolean
  highlight?: boolean
}

export const NumberInput = ({
  id,
  value,
  disabled,
  autoFocus,
  onFocus,
  onChange,
  onBlur,
  min,
  max,
  type,
  clearable,
  highlight = false
}: LimitInputProps) => {
  const [currValue, setCurrValue] = useState(value)
  const [focused, setFocused] = useState(false)
  const wrapperRef = useRef(null)

  useBlurOnEnter(id)

  useOnClickOutside(wrapperRef, () => {
    setFocused(false)
  })

  useEffect(() => {
    if (!disabled) {
      currValue && (isNaN(currValue) || currValue === 0) && clearable ? setCurrValue(null) : setCurrValue(value)
      currValue && !focused && value !== currValue && onBlur(currValue)
      clearable && !currValue && onBlur(undefined)
    }
  }, [focused])

  return (
    <NumberInputWrapper ref={wrapperRef} focused={focused || highlight} disabled={disabled}>
      <Button
        value={'-'}
        buttonStyle={ButtonStyle.GHOST}
        size={ButtonSize.XSMALL}
        disabled={disabled || (currValue !== null && currValue <= min)}
        clickHandler={e => {
          e.preventDefault()
          e.stopPropagation()
          setFocused(true)
          currValue ? setCurrValue(currValue - 1) : setCurrValue(min)
        }}
      />
      <StyledInput
        id={id}
        value={currValue || ''}
        disabled={disabled}
        autoFocus={autoFocus}
        onFocus={() => {
          setFocused(true)
          onFocus && onFocus()
        }}
        onBlur={e => {
          if (!disabled) {
            const inputValue = isNaN(e.target.valueAsNumber) ? (clearable ? null : value) : e.target.valueAsNumber
            inputValue &&
              (inputValue > max ? setCurrValue(max) : inputValue < min ? setCurrValue(min) : setCurrValue(inputValue))
            setFocused(false)
          }
        }}
        onChange={e => {
          if (!disabled) {
            const inputValue = isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber
            if (inputValue) {
              setCurrValue(type === '-' ? -Math.abs(inputValue) : Math.abs(inputValue))
              onChange && onChange(type === '-' ? -Math.abs(inputValue) : Math.abs(inputValue))
            } else {
              setCurrValue(null)
            }
          }
        }}
        type={'number'}
        min={min}
        max={max}
      />
      <Button
        value={'+'}
        buttonStyle={ButtonStyle.GHOST}
        size={ButtonSize.XSMALL}
        disabled={disabled || (currValue !== null && currValue >= max)}
        clickHandler={e => {
          e.preventDefault()
          e.stopPropagation()
          setFocused(true)
          currValue ? setCurrValue(currValue + 1) : setCurrValue(min)
        }}
      />
    </NumberInputWrapper>
  )
}

interface NumberInputWrapperProps {
  focused: boolean
  disabled?: boolean
}

const NumberInputWrapper = styled.div<NumberInputWrapperProps>`
  ${tw`flex w-full max-w-[154px] items-center justify-center gap-2 border rounded py-0.5`}
  ${({ focused, disabled }) =>
    focused
      ? tw`border-transparent outline-primary-500`
      : disabled
        ? tw`border-gray-400/30 cursor-not-allowed hover:border-gray-400/30`
        : tw`border-gray-400/50 hover:border-gray-400`}
  input {
    ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`)}
  }
`

const StyledInput = styled.input`
  ${tw`min-w-14 bg-transparent text-gray-100 shadow-none outline-none rounded m-0 p-0 h-fit text-center focus:font-semibold focus:text-gray-50 focus:shadow-none`}
  font-size: clamp(0.875rem, 1.5vw, 1rem);

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    appearance: none;
  }
`
