import styled from 'styled-components'
import tw from 'twin.macro'
import { GrayText, Heading } from '../shared/TextComponents'
import React, { ReactNode } from 'react'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import caretIcon from '../../assets/svg/directional/caret.svg'
import { Tenant } from '../../api/system-admin'

interface DimmedRowProps {
  hasAction?: boolean
}

export const DimmedContainer = styled.div<DimmedRowProps>`
  ${tw`bg-gray-500/20 py-4 px-6`}
  ${({ hasAction }) => hasAction && tw`cursor-pointer hover:bg-gray-500/30`}
`

export const DimmedRow = styled(DimmedContainer)`
  ${tw`flex items-center justify-between w-full`}
`

export const DimmedListRow = styled(DimmedRow)`
  ${tw`my-1 first:mt-0`}
`

interface LabeledMenuProps {
  label: string
  menu: ReactNode
}

export const LabeledMenu = ({ label, menu }: LabeledMenuProps) => {
  return (
    <div className={'flex flex-col gap-1'}>
      <GrayText>{label}</GrayText>
      {menu}
    </div>
  )
}

interface TenantHeaderProps {
  tenant: Tenant
  selectedTenant: Tenant | undefined
  setSelectedTenant: (tenant: Tenant | undefined) => void
  rightComponent?: ReactNode
}

export const TenantHeader = ({ tenant, selectedTenant, setSelectedTenant, rightComponent }: TenantHeaderProps) => {
  return (
    <DimmedRow
      className={`${selectedTenant?.id === tenant.id && 'sticky top-0 z-[1] backdrop-blur border-b border-gray-500'}`}
      hasAction={true}
      onClick={() => (selectedTenant?.id !== tenant.id ? setSelectedTenant(tenant) : setSelectedTenant(undefined))}
    >
      <div className={'flex items-center gap-3'}>
        <CustomIcon
          iconType={IconType.VECTOR}
          path={caretIcon}
          styles={`w-6 h-6 bg-gray-50 transition-all ease-in-out duration-200 ${selectedTenant?.id === tenant.id ? 'rotate-180' : 'rotate-0'}`}
        />
        <Heading>{tenant.name}</Heading>
      </div>
      {rightComponent}
    </DimmedRow>
  )
}
