import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

interface DataContainerProps {
  children: React.ReactNode
  looseSpacing?: boolean
  flexCol?: boolean
  subLayout?: boolean
}

export const DataContainer = ({
  children,
  looseSpacing = false,
  flexCol = false,
  subLayout = false
}: DataContainerProps) => {
  return subLayout ? (
    <BasicStyledContainer id={'sub-layout-container'} className={'flex-col'}>
      {children}
    </BasicStyledContainer>
  ) : (
    <CenteredDataContainer
      id={'data-container'}
      className={'no-scrollbar'}
      looseSpacing={looseSpacing}
      flexCol={flexCol}
    >
      {children}
    </CenteredDataContainer>
  )
}

const BasicStyledContainer = styled.div`
  ${tw`flex rounded-md bg-gray-600/90 shadow-container`}
`

interface StyledContainerProps {
  looseSpacing: boolean
  flexCol: boolean
}

const CenteredDataContainer = styled(BasicStyledContainer)<StyledContainerProps>`
  ${tw`relative items-center justify-center py-7 px-4`}
  ${({ flexCol }) => (flexCol ? tw`flex-col` : tw`flex-row`)}
  ${({ looseSpacing }) => (looseSpacing ? tw`sm:pb-6 sm:p-8 xl:p-12` : tw`sm:p-8`)}
`

export const TabsContainer = styled(BasicStyledContainer)`
  ${tw`flex flex-col transition-all ease-in-out gap-4 py-4 px-2`}
`
