import React from 'react'
import { IndicatorWrapper } from './SeverityLevelIndicator'

export enum StatusType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  OK = 'OK',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}

interface StatusIndicatorProps {
  type: StatusType
}

export const StatusIndicator = ({ type }: StatusIndicatorProps) => {
  const color =
    type === StatusType.OK
      ? 'bg-success-500/40 text-tertiary-200'
      : type === StatusType.ERROR
        ? 'bg-error-500/50 text-primary-500'
        : type === StatusType.WARNING
          ? 'bg-warn-500/35 text-warn-500'
          : 'bg-gray-300/40 text-gray-200'

  return <IndicatorWrapper className={`${color}`}>{type.toLowerCase().replace('_', ' ')}</IndicatorWrapper>
}
