import { formatNumber } from '../../../utils/formats'
import React, { useEffect, useRef, useState } from 'react'
import { Vendor } from '../../../utils/vendors'
import styled from 'styled-components'
import tw from 'twin.macro'
import { MissingDataNotification } from '../MissingDataNotification'
import { Loading } from '../Loading'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { CostChangeIndicator } from '../indicators/CostChangeIndicator'
import { useMousePosition } from '../../../hooks/useMousePosition'
import { useTranslation } from 'react-i18next'
import { GrayText, Heading3, WhiteText } from '../TextComponents'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { Button, ButtonSize, ButtonStyle } from '../buttons/Button'
import { CustomIcon } from '../CustomIcon'
import caretIcon from '../../../assets/svg/directional/caret.svg'
import { VendorIndicator } from '../indicators/VendorIndicator'

const SCROLLABLE_DATA_BLOCK_ID = 'scrollable-data-block'

export interface DataBlockData {
  vendor: Vendor
  label: string
  value: number
  unit?: string
  change?: number
}

interface DataBlockProps {
  id: string
  headerIcon: React.ReactElement
  heading: string
  subHeading?: string
  data: DataBlockData[]
  forecastIndicator?: boolean
  changeIndicator?: boolean
  changeIndicatorTooltip?: string
  loading: boolean
}

export const DataBlock = ({
  id,
  headerIcon,
  heading,
  subHeading,
  data,
  forecastIndicator,
  changeIndicator,
  changeIndicatorTooltip,
  loading
}: DataBlockProps) => {
  const { t } = useTranslation()
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipVendor, setTooltipVendor] = useState<Vendor>()
  const [blockOpen, setBlockOpen] = useState(false)
  const mousePosition = useMousePosition()
  const ref = useRef(null)
  const visibleCount = 6

  useEffect(() => {
    !blockOpen && document.getElementById(SCROLLABLE_DATA_BLOCK_ID)?.scroll({ top: 0, behavior: 'smooth' })
  }, [blockOpen])

  useOnClickOutside(ref, () => {
    setShowTooltip(false)
  })

  if (loading || !data.length) {
    return (
      <Block ref={ref} blockOpen={blockOpen}>
        <HeadingWrapper>
          <div className={'flex items-center gap-3'}>
            {headerIcon}
            <div>
              <Heading3>{heading}</Heading3>
              <SubHeading>{subHeading}</SubHeading>
            </div>
          </div>
        </HeadingWrapper>
        <div className={'flex h-full p-5 justify-center items-center'}>
          {loading ? <Loading height={60} paddingY={'4.3rem'} /> : <MissingDataNotification justify={'center'} />}
        </div>
      </Block>
    )
  }

  return (
    <>
      <Block ref={ref} blockOpen={blockOpen} id={SCROLLABLE_DATA_BLOCK_ID}>
        <HeadingWrapper className={'sticky top-0 backdrop-filter backdrop-blur-xl z-[1]'}>
          <div className={'flex items-center gap-3'}>
            {headerIcon}
            <div>
              <Heading3>{heading}</Heading3>
              <SubHeading>{subHeading}</SubHeading>
            </div>
          </div>
          {data.length > visibleCount && (
            <Button
              size={ButtonSize.XSMALL}
              buttonStyle={ButtonStyle.GHOST}
              value={
                <CustomIcon
                  path={caretIcon}
                  styles={`w-6 h-6 bg-gray-300 ${blockOpen && '-rotate-180'}`}
                  onClick={() => setBlockOpen(!blockOpen)}
                />
              }
            />
          )}
        </HeadingWrapper>

        <div className={'flex flex-col justify-between h-full'}>
          <div className={'flex flex-col gap-5 py-5 px-3 sm:px-5'} onMouseOut={() => setShowTooltip(false)}>
            {data
              .filter((_, index) => blockOpen || index < visibleCount)
              .map((item, index) => (
                <Row
                  key={index}
                  onMouseMove={() => {
                    setTooltipVendor(item.vendor)
                  }}
                >
                  <WhiteText
                    className={'cursor-default'}
                    onMouseOver={() => {
                      setShowTooltip(true)
                    }}
                  >
                    {item.label}
                  </WhiteText>
                  <ValueVendorIndicator
                    id={`${id}-${index}`}
                    item={item}
                    forecastIndicator={forecastIndicator}
                    changeIndicator={changeIndicator}
                    changeIndicatorTooltip={changeIndicatorTooltip}
                    setShowTooltip={setShowTooltip}
                  />
                </Row>
              ))}
          </div>
          {data.length > visibleCount && (
            <GrayText
              className={`w-full text-center cursor-pointer hover:text-gray-50 ${!blockOpen ? 'sticky -bottom-1 py-3 backdrop-blur-xl' : 'py-5'}`}
              onClick={() => setBlockOpen(!blockOpen)}
            >
              {blockOpen
                ? `${t('common.showLess')}... (${data.filter((_, index) => index < visibleCount).length})`
                : `${t('common.showMore')}... (${data.length - visibleCount})`}
            </GrayText>
          )}
        </div>
      </Block>
      {showTooltip && (
        <InfoTooltip vendor={tooltipVendor} x={mousePosition.x} y={mousePosition.y}>
          {tooltipVendor}
        </InfoTooltip>
      )}
    </>
  )
}

interface BlockProps {
  blockOpen: boolean
}

const Block = styled.div<BlockProps>`
  ${tw`flex flex-col flex-1 min-w-80 max-w-190 rounded-md border border-gray-500 text-gray-50 shadow-xs max-h-100 overflow-hidden xl:min-w-100`}
  ${({ blockOpen }) => blockOpen && tw`h-full max-h-[480px] overflow-y-auto`}
`

const HeadingWrapper = styled.div`
  ${tw`flex justify-between items-center py-4 px-3 border-b border-gray-500 sm:px-5`}
`

const SubHeading = styled.div`
  ${tw`text-gray-200 first-letter:capitalize`}
`

const Row = styled.div`
  ${tw`flex justify-between gap-8 w-full`}
`

interface ValueVendorIndicatorProps {
  id: string
  item: DataBlockData
  forecastIndicator?: boolean
  changeIndicator?: boolean
  changeIndicatorTooltip?: string
  setShowTooltip: (value: boolean) => void
}

const ValueVendorIndicator = ({
  id,
  item,
  forecastIndicator,
  changeIndicator,
  changeIndicatorTooltip,
  setShowTooltip
}: ValueVendorIndicatorProps) => {
  const { t } = useTranslation()
  return (
    <div className={'flex items-center justify-end gap-3 text-right'}>
      <WhiteText id={id} className={'w-max'}>
        {formatNumber(item.value)} {item.unit}
      </WhiteText>
      {forecastIndicator
        ? (item.change || item.change === 0) && (
            <CostChangeIndicator
              change={item.change}
              tooltip={`${
                item.change === 0
                  ? t('costs.noForecastChange')
                  : item.change && item.change > 0
                    ? t('costs.forecastIncrease')
                    : t('costs.forecastDecrease')
              } ${t('costs.fromLastMonth')}`}
            />
          )
        : (item.change || item.change === 0) &&
          changeIndicator && <CostChangeIndicator change={item.change} tooltip={changeIndicatorTooltip} />}
      <VendorIndicator vendor={item.vendor} onHover={() => setShowTooltip(true)} type={'plain'} />
    </div>
  )
}
