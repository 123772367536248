import styled from 'styled-components'
import tw from 'twin.macro'
import { CostAnomalyNotification, NotificationState } from '../../api/notifications'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { NotificationsContext } from '../../state/context/NotificationsContext'
import { ToastActionComponent } from '../../global-message/GlobalMessage'

export enum CostNotificationDataKey {
  VENDOR = 'costNotificationVendor',
  PROJECT_ID = 'costNotificationProjectId',
  PROJECT_NAME = 'costNotificationProjectName',
  SERVICE = 'costNotificationService'
}

export const NotificationsContentCol = styled.div`
  ${tw`flex flex-col w-full divide-y divide-gray-500`}
`

interface ViewNotificationCostsButtonProps {
  notification: CostAnomalyNotification
  updateReadState: (notifications: CostAnomalyNotification[], status: NotificationState) => void
}

export const ViewNotificationCostsButton = ({ notification, updateReadState }: ViewNotificationCostsButtonProps) => {
  const { t } = useTranslation()
  const { setNotificationsOpen } = useContext(NotificationsContext)
  const navigate = useNavigate()

  const directToNotificationCosts = () => {
    sessionStorage.setItem(CostNotificationDataKey.VENDOR, notification.vendor)
    sessionStorage.setItem(CostNotificationDataKey.PROJECT_ID, notification.project.id)
    sessionStorage.setItem(CostNotificationDataKey.PROJECT_NAME, notification.project.name)
    notification.service && sessionStorage.setItem(CostNotificationDataKey.SERVICE, notification.service)

    navigate('/costs', { state: { from: 'cost-notification' } })
    setNotificationsOpen(false)
    toast.dismiss()
  }

  return (
    <ToastActionComponent
      label={t('notifications.costAnomalies.costAnomaly.viewCosts')}
      onClick={() => {
        directToNotificationCosts()
        updateReadState([notification], NotificationState.READ)
      }}
    />
  )
}
