import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import checkmarkIcon from '../../assets/svg/symbols/check-heavy.svg'
import copypasteIcon from '../../assets/svg/actions/copypaste.svg'
import expandIcon from '../../assets/svg/actions/expand.svg'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { CustomIcon, IconType } from './CustomIcon'

interface ClipboardCopyProps {
  customDisplayText?: string
  copyText: string
  secondCopyText?: string
  limit: number
  exitDelay?: number
  restrictClickArea?: boolean
}

export const ClipboardCopy = ({
  customDisplayText,
  copyText,
  secondCopyText,
  limit,
  exitDelay = 1000,
  restrictClickArea = false
}: ClipboardCopyProps) => {
  const [copiedText, setCopiedText] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const dialogueRef = useRef(null)
  const componentRef = useRef(null)
  const { t } = useTranslation()

  useOnClickOutside(componentRef, () => setIsOpen(false))

  const copyToClipboard = (text: string) => {
    return 'clipboard' in navigator ? navigator.clipboard.writeText(text) : document.execCommand('copy', true, text)
  }

  const handleCopyClick = (text: string) => {
    try {
      copyToClipboard(text)
      setCopiedText(text)
      setTimeout(() => {
        setIsOpen(false)
        setCopiedText('')
      }, exitDelay)
    } catch (err) {
      console.log('Error copying to device clipboard: ' + err)
    }
  }

  const toggleClipboard = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsOpen(!isOpen)
  }

  const displayText = customDisplayText ? customDisplayText : copyText

  if (displayText === copyText && !secondCopyText)
    return (
      <VisibleContent>
        <div className={'break-all'}>
          {displayText.length <= limit ? displayText : displayText.substring(0, limit) + '... '}
        </div>
        <CustomIcon
          iconType={IconType.VECTOR}
          path={copiedText === copyText ? checkmarkIcon : copypasteIcon}
          styles={'w-3.5 h-3.5 bg-gray-50'}
          onClick={() => handleCopyClick(copyText)}
          tooltipText={copiedText === copyText ? t('common.copySuccessful') : t('common.copyToClipboard')}
        />
      </VisibleContent>
    )

  return (
    <div ref={componentRef}>
      <VisibleContent
        onClick={event => {
          if (!restrictClickArea) {
            toggleClipboard(event)
          }
        }}
      >
        <div className={'flex flex-col'}>
          <div className={'break-all'}>
            {displayText.length <= limit ? displayText : displayText.substring(0, limit) + '... '}
          </div>
          {secondCopyText && (
            <div className={'text-gray-200 text-90 break-all'}>
              {secondCopyText.length <= limit ? secondCopyText : secondCopyText.substring(0, limit) + '...'}
            </div>
          )}
        </div>
        <ExpandIcon
          onClick={event => toggleClipboard(event)}
          src={expandIcon}
          alt={t('common.clickToExpand')}
          title={t('common.clickToExpand')}
        />
      </VisibleContent>

      <CSSTransition nodeRef={dialogueRef} in={isOpen} timeout={300} onExited={() => setCopiedText('')}>
        {() => {
          return (
            <CopyDialogue isOpen={isOpen} ref={dialogueRef} className={'backdrop-filter backdrop-blur'}>
              <CopyElement isCopied={copiedText === copyText} copyText={copyText} handleCopyClick={handleCopyClick} />

              {secondCopyText && (
                <CopyElement
                  isCopied={copiedText === secondCopyText}
                  copyText={secondCopyText}
                  handleCopyClick={handleCopyClick}
                />
              )}
            </CopyDialogue>
          )
        }}
      </CSSTransition>
    </div>
  )
}

const VisibleContent = styled.div`
  ${tw`hover:cursor-pointer inline-flex flex-row gap-3 items-center`}
`

const ExpandIcon = styled.img`
  ${tw`w-4 h-full`}
`

interface CopyElementProps {
  copyText: string
  isCopied: boolean
  handleCopyClick: (text: string) => void
}

const CopyElement = ({ copyText, isCopied, handleCopyClick }: CopyElementProps) => {
  const { t } = useTranslation()
  return (
    <div className={'flex'}>
      <Text>{copyText}</Text>
      <Action
        onClick={event => {
          event.stopPropagation()
          handleCopyClick(copyText)
        }}
      >
        <ActionWrapper>
          <CustomIcon
            iconType={IconType.VECTOR}
            path={isCopied ? checkmarkIcon : copypasteIcon}
            styles={'w-4 h-4 bg-gray-50'}
            tooltipText={isCopied ? t('common.copySuccessful') : t('common.copyToClipboard')}
          />
        </ActionWrapper>
      </Action>
    </div>
  )
}

interface CopyDialogueProps {
  isOpen: boolean
}

const CopyDialogue = styled.div<CopyDialogueProps>`
  ${tw`flex flex-col absolute divide-y divide-gray-500 -ml-8 mt-6 z-11 shadow-sm rounded-lg border bg-gray-700/40 border-gray-500 opacity-0 origin-top transition-all duration-100 ease-in-out`}
  ${props => (props.isOpen ? tw`flex` : tw`hidden`)}
  &.enter-done {
    ${tw`opacity-100 mt-1`}
  }

  &.exit-active {
    ${tw`opacity-0 mt-6`}
  }
`

const Text = styled.div`
  ${tw`p-4 selection:bg-primary-500 min-w-36 w-full selection:text-gray-700 break-all flex items-center text-left`}
`

const Action = styled.div`
  ${tw`p-4 border-l border-dashed border-gray-500 hover:bg-gray-600/50 hover:cursor-pointer`}
`

const ActionWrapper = styled.div`
  ${tw`h-full flex flex-col gap-2 text-75 text-gray-50 items-center justify-center text-center`}
  div:nth-of-type(2) {
    ${tw`w-[3.4rem]`}
  }
`
