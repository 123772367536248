import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CapitalizedText, GrayText } from '../shared/TextComponents'
import {
  BUDGET_COST_ANOMALY_STATES,
  ChangeType,
  CostAnomalyNotification,
  CostAnomalyState,
  DECREASING_COST_ANOMALY_STATES,
  getCostAnomalyNotifications,
  INCREASING_COST_ANOMALY_STATES
} from '../../api/notifications'
import { useCancelToken } from '../../api/client'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { ModalContext } from '../../state/context/ModalContext'
import { useErrorHandling } from '../../hooks/handleError'
import { ModalActions } from '../shared/modal/Modal'
import { Button, ButtonStyle, ButtonType } from '../shared/buttons/Button'
import { formatNumber } from '../../utils/formats'
import { NotificationsContext } from '../../state/context/NotificationsContext'
import { useNavigate } from 'react-router-dom'
import settings from '../../assets/svg/objects/cog.svg'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import { getCurrency } from '../../utils/Currency'
import {
  changeCostAnomalyProjectLimits,
  changeCostAnomalyServiceLimits,
  CostAnomalyLimitData
} from '../../api/settings/notifications'
import { setNotificationProject, setNotificationService } from '../../state/storage'
import { CostAnomalyBudgetInputs, CostAnomalyLimitInputs } from '../shared/CostAnomalyInputs'
import { CostAnomalyLimits } from '../../api/admin/alerts'

interface CostAnomalySettingsModalProps {
  notification: CostAnomalyNotification
}

export const CostNotificationModalHeader = ({ notification }: CostAnomalySettingsModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const { setNotificationsOpen } = useContext(NotificationsContext)
  const navigate = useNavigate()

  return (
    <div className={'flex gap-5 items-center text-center'}>
      <CustomIcon
        styles={'w-5 h-5 bg-gray-200 hover:bg-gray-50'}
        iconType={IconType.VECTOR}
        path={settings}
        onClick={() => {
          setModal(null)
          setNotificationsOpen(false)
          navigate(`/settings/notifications/${notification.vendor.toLowerCase()}`)
        }}
        tooltipText={t('notifications.costAnomalies.costAnomaly.limitChangeModal.viewAllSettings')}
        tooltipStyles={'mt-12 w-max ml-5'}
      />
      <CapitalizedText className={'w-full'}>
        {t('notifications.costAnomalies.costAnomaly.menuOptions.changeLimits')}
      </CapitalizedText>
    </div>
  )
}

export const CostNotificationModalBody = ({ notification }: CostAnomalySettingsModalProps) => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const navigate = useNavigate()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const { setNotificationsOpen } = useContext(NotificationsContext)
  const { setNotifications } = useContext(NotificationsContext)
  const handleError = useErrorHandling()
  const [projectLimits, setProjectLimits] = useState<CostAnomalyLimitData>(notification.projectLimits)
  const [serviceLimits, setServiceLimits] = useState<CostAnomalyLimitData | null>(notification.serviceLimits)
  const [currentLimits, setCurrentLimits] = useState<CostAnomalyLimitData>(notification.anomalyLimits)
  const [loading, setLoading] = useState(false)
  const [budgetAlertThreshold, setBudgetAlertThreshold] = useState(
    currentLimits.monthlyBudget && currentLimits.budgetAlertPercent
      ? currentLimits.monthlyBudget * (currentLimits.budgetAlertPercent / 100)
      : null
  )
  const isIncreasing = INCREASING_COST_ANOMALY_STATES.includes(notification.anomalyState)

  const changeProjectLimits = (newLimits: CostAnomalyLimitData) => {
    const cancelToken = createCancelToken()
    setLoading(true)
    changeCostAnomalyProjectLimits(
      notification.vendor,
      [
        {
          vendor: notification.vendor,
          project: notification.project,
          limits: newLimits,
          costNotificationsDisabledAt: null,
          isSubscribedToEmail: !!notification.emailSubscriptions?.project
        }
      ],
      cancelToken.token
    )
      .then(() => {
        getCostAnomalyNotifications(cancelToken.token)
          .then(resp => {
            setNotifications(resp)
            setProjectLimits(newLimits)
            setCurrentLimits(
              resp.find(n => n.notificationId === notification.notificationId)?.anomalyLimits || currentLimits
            )
            setBudgetAlertThreshold(
              newLimits.monthlyBudget && newLimits.budgetAlertPercent
                ? newLimits.monthlyBudget * (newLimits.budgetAlertPercent / 100)
                : null
            )
          })
          .catch(handleError)
      })
      .then(() =>
        setMessage({
          type: MessageType.SUCCESS,
          message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
        })
      )
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  const changeServiceLimits = (newLimits: CostAnomalyLimitData) => {
    const cancelToken = createCancelToken()
    setLoading(true)
    notification.service &&
      changeCostAnomalyServiceLimits(
        notification.vendor,
        [
          {
            vendor: notification.vendor,
            service: notification.service,
            limits: newLimits,
            isSubscribedToEmail: !!notification.emailSubscriptions?.service
          }
        ],
        cancelToken.token
      )
        .then(() => {
          getCostAnomalyNotifications(cancelToken.token)
            .then(resp => {
              setNotifications(resp)
              setServiceLimits(newLimits)
              setCurrentLimits(
                resp.find(n => n.notificationId === notification.notificationId)?.anomalyLimits || currentLimits
              )
            })
            .catch(handleError)
        })
        .then(() =>
          setMessage({
            type: MessageType.SUCCESS,
            message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
          })
        )
        .catch(handleError)
        .finally(() => setLoading(false))
  }

  return (
    <>
      <div className={'flex flex-col items-center divide-y divide-gray-500/60 max-w-120'}>
        <ModalInputsBlock
          label={notification.project.name}
          limits={projectLimits}
          changeLimits={changeProjectLimits}
          state={notification.anomalyState}
          setBudgetAlertThreshold={setBudgetAlertThreshold}
        />
        {notification.service && serviceLimits && (
          <ModalInputsBlock
            label={notification.service}
            limits={serviceLimits}
            changeLimits={changeServiceLimits}
            state={notification.anomalyState}
          />
        )}
      </div>
      <GrayText className={'max-w-116 text-80'}>
        {INCREASING_COST_ANOMALY_STATES.includes(notification.anomalyState) ||
        DECREASING_COST_ANOMALY_STATES.includes(notification.anomalyState) ? (
          <Trans>
            {t('notifications.costAnomalies.costAnomaly.limitChangeModal.limitsDescription', {
              changeLabel: isIncreasing
                ? t('notifications.costAnomalies.increase')
                : t('notifications.costAnomalies.decrease'),
              percent: isNaN(isIncreasing ? currentLimits.increasePercent : currentLimits.decreasePercent)
                ? 1
                : isIncreasing
                  ? currentLimits.increasePercent
                  : currentLimits.decreasePercent,
              impact: isNaN(isIncreasing ? currentLimits.increaseMonthlyImpact : currentLimits.decreaseMonthlyImpact)
                ? 1
                : formatNumber(
                    isIncreasing ? currentLimits.increaseMonthlyImpact : currentLimits.decreaseMonthlyImpact
                  ),
              currency: getCurrency(notification.currency).symbol
            })}
          </Trans>
        ) : (
          budgetAlertThreshold && (
            <Trans>
              {t('settings.notifications.budgetThresholdDescription', {
                amount: formatNumber(budgetAlertThreshold, 0),
                currency: getCurrency(notification.currency).symbol
              })}
            </Trans>
          )
        )}
      </GrayText>
      <ModalActions>
        <Button
          value={t('notifications.costAnomalies.costAnomaly.limitChangeModal.viewSettings')}
          clickHandler={() => {
            setNotificationProject(notification.project.id)
            notification.service && setNotificationService(notification.service)
            setNotificationsOpen(false)
            setModal(null)
            navigate(`/settings/notifications/${notification.vendor.toLowerCase()}`)
          }}
          buttonStyle={ButtonStyle.SECONDARY}
          type={ButtonType.FORM}
          disabled={loading}
        />
      </ModalActions>
    </>
  )
}

interface ModalInputsBlockProps {
  label: string
  limits: CostAnomalyLimitData
  changeLimits: (limits: CostAnomalyLimitData) => void
  state: CostAnomalyState
  setBudgetAlertThreshold?: (value: number | null) => void
}

const ModalInputsBlock = ({ label, limits, changeLimits, state, setBudgetAlertThreshold }: ModalInputsBlockProps) => {
  return (
    <div className={'flex flex-col gap-4 w-full px-5 py-9 first:pt-5 last:pb-5'}>
      <div className={'text-center text-gray-50 font-semibold'}>{label}</div>
      <div className={'flex flex-col gap-4 w-full items-center'}>
        {DECREASING_COST_ANOMALY_STATES.includes(state) ? (
          <CostAnomalyLimitInputs
            type={ChangeType.DECREASING}
            limits={{ percent: limits.decreasePercent, monthlyImpact: limits.decreaseMonthlyImpact }}
            currency={limits.currency}
            onBlur={(value?: CostAnomalyLimits) => {
              value &&
                (value.percent !== limits.decreasePercent || value.monthlyImpact !== limits.decreaseMonthlyImpact) &&
                changeLimits({
                  ...limits,
                  decreasePercent: value.percent,
                  decreaseMonthlyImpact: value.monthlyImpact
                })
            }}
          />
        ) : INCREASING_COST_ANOMALY_STATES.includes(state) ? (
          <CostAnomalyLimitInputs
            key={JSON.stringify(limits)}
            type={ChangeType.INCREASING}
            limits={{ percent: limits.increasePercent, monthlyImpact: limits.increaseMonthlyImpact }}
            currency={limits.currency}
            onBlur={(value?: CostAnomalyLimits) => {
              value &&
                (value.percent !== limits.increasePercent || value.monthlyImpact !== limits.increaseMonthlyImpact) &&
                changeLimits({
                  ...limits,
                  increasePercent: value.percent,
                  increaseMonthlyImpact: value.monthlyImpact
                })
            }}
          />
        ) : BUDGET_COST_ANOMALY_STATES.includes(state) ? (
          <CostAnomalyBudgetInputs
            key={JSON.stringify(limits)}
            showDescription={false}
            monthlyBudget={limits.monthlyBudget}
            budgetAlertPercent={limits.budgetAlertPercent}
            currency={limits.currency}
            showInitially={!!(limits.monthlyBudget && limits.budgetAlertPercent)}
            onClear={() => {
              changeLimits({
                ...limits,
                monthlyBudget: null,
                budgetAlertPercent: null
              })
              setBudgetAlertThreshold && setBudgetAlertThreshold(null)
            }}
            onBudgetChange={value => {
              value &&
                limits.budgetAlertPercent &&
                setBudgetAlertThreshold &&
                setBudgetAlertThreshold(value * (limits.budgetAlertPercent / 100))
            }}
            onBudgetBlur={value => {
              value !== limits.monthlyBudget &&
                changeLimits({
                  ...limits,
                  monthlyBudget: value,
                  budgetAlertPercent: limits.budgetAlertPercent || 75
                })
            }}
            onPercentChange={value => {
              value &&
                limits.monthlyBudget &&
                setBudgetAlertThreshold &&
                setBudgetAlertThreshold(limits.monthlyBudget * (value / 100))
            }}
            onPercentBlur={value => {
              value !== limits.budgetAlertPercent &&
                changeLimits({
                  ...limits,
                  budgetAlertPercent: value
                })
            }}
          />
        ) : null}
      </div>
    </div>
  )
}
