import { TabOptionsWrapper } from '../shared/tabs/TabSharedComponents'
import { Tab } from '../shared/tabs/Tab'
import { MeatballsMenu, MeatballsMenuOption } from '../shared/MeatballsMenu'
import { CostAnomalyNotification } from '../../api/notifications'
import { useTranslation } from 'react-i18next'
import { useMousePosition } from '../../hooks/useMousePosition'
import { GrayText } from '../shared/TextComponents'
import { SearchInput } from '../shared/filters/SearchInput'
import React from 'react'
import { NotificationsTab } from './Notifications'

interface NotificationsTabsProps {
  inboxUnread: number
  deletedUnread: number
  selectedTab: NotificationsTab
  setSelectedTab: (tab: NotificationsTab) => void
}

export const NotificationsTabOptions = ({
  inboxUnread,
  deletedUnread,
  selectedTab,
  setSelectedTab
}: NotificationsTabsProps) => {
  const { t } = useTranslation()
  const mousePosition = useMousePosition()

  return (
    <TabOptionsWrapper>
      <Tab
        styles={'lg:max-w-40'}
        tabLabel={
          <>
            {NotificationsTab.INBOX}
            {inboxUnread > 0 && <span className={'ml-2.5'}>{inboxUnread}</span>}
          </>
        }
        tabValue={NotificationsTab.INBOX}
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(NotificationsTab.INBOX)}
        tooltipText={t(`notifications.INBOX.tabTooltip`)}
        tooltipPosition={{ x: mousePosition.x / 14, y: mousePosition.y + 16 }}
      />
      <Tab
        styles={'lg:max-w-40'}
        tabLabel={
          <>
            {NotificationsTab.DELETED}
            {deletedUnread > 0 && <span className={'ml-2.5'}>{deletedUnread}</span>}
          </>
        }
        tabValue={NotificationsTab.DELETED}
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(NotificationsTab.DELETED)}
        tooltipText={t(`notifications.DELETED.tabTooltip`)}
        tooltipPosition={{ x: mousePosition.x / 7, y: mousePosition.y + 16 }}
      />
    </TabOptionsWrapper>
  )
}

interface TabDescriptionProps {
  selectedTab: NotificationsTab
  searchText: string
  setSearchText: (searchText: string) => void
  menuOptions: MeatballsMenuOption[]
  notifications: CostAnomalyNotification[]
}

export const TabDescriptionBlock = ({
  selectedTab,
  searchText,
  setSearchText,
  menuOptions,
  notifications
}: TabDescriptionProps) => {
  const { t } = useTranslation()
  const mousePosition = useMousePosition()

  return (
    <div className={'flex items-center justify-end gap-8 p-4'}>
      <div className={'flex w-full items-center gap-1'}>
        <GrayText>
          {searchText !== ''
            ? t(`notifications.${selectedTab}.descriptionSearch`, { count: notifications.length })
            : notifications.length > 1 &&
              t(`notifications.${selectedTab}.description`, { count: notifications.length })}
        </GrayText>
      </div>
      <div className={'flex gap-2 items-center w-full max-w-80'}>
        <SearchInput
          id={'notifications-search'}
          size={'small'}
          searchText={searchText}
          setSearchText={setSearchText}
          useTooltip={true}
          tooltipPosition={{ x: mousePosition.x / 4, y: mousePosition.y + 16 }}
          tooltipText={t('notifications.searchInputTooltip')}
        />
        <MeatballsMenu options={menuOptions} isDisabled={!notifications.length} />
      </div>
    </div>
  )
}
