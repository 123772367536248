import { UserRole } from '../../../api/auth'
import { TFunction, useTranslation } from 'react-i18next'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import React, { useContext, useEffect, useState } from 'react'
import { adminCreateUser, adminDeleteUser, getOrganizationUsers, OrganizationUser } from '../../../api/admin/users'
import axios from 'axios'
import { Loading } from '../../shared/Loading'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import { SearchInput } from '../../shared/filters/SearchInput'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { formatDate, testCognitoEmail } from '../../../utils/formats'
import { Button, ButtonSize, ButtonStyle, ButtonType, DeleteButton } from '../../shared/buttons/Button'
import { CapitalizedText, GrayText } from '../../shared/TextComponents'
import { ModalActions, ModalText, SpotterModal } from '../../shared/modal/Modal'
import { ModalContext, ModalState } from '../../../state/context/ModalContext'
import { useForm } from 'react-hook-form'
import { CustomInput, CustomLabel } from '../../shared/filters/FormComponents'
import { MessageContext, MessageState, MessageType } from '../../../state/context/MessageContext'
import { NestedPageHeader } from '../../shared/NestedPageHeader'
import { ScrollTable, ScrollTableRowItem } from '../../shared/containers/ScrollTable'
import { AdminContentWrapper } from '../adminStyles'
import { ACCESS_RIGHT_ROLES, ADMIN_ROLES, VISIBILITY_ROLES } from '../roleConstants'
import { HelpTooltip, HelpTooltipInfoBlock, HelpTooltipModal } from '../../shared/tooltips/HelpTooltip'
import adminIcon from '../../../assets/svg/sidebar/admin-icon.svg'
import userIcon from '../../../assets/svg/objects/user-2.svg'
import keyIcon from '../../../assets/svg/objects/key.svg'
import profileIcon from '../../../assets/svg/sidebar/user-settings-icon.svg'
import { firstLogin, removeFirstLogin } from '../../../state/storage'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import editIcon from '../../../assets/svg/actions/edit.svg'
import { Layout } from '../../../layout/Layout'

export const UserList = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { authInfo } = useContext(UserInfoContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { setMessage } = useContext<MessageState>(MessageContext)
  const navigate = useNavigate()
  const handleError = useErrorHandling()
  const [searchParams] = useSearchParams()
  const openModal = Boolean(searchParams.get('openModal'))
  const [step, setStep] = useState<0 | 1>(firstLogin() ? 0 : 1)
  const [loading, setLoading] = useState<boolean>(true)
  const [endUsers, setEndUsers] = useState<OrganizationUser[]>([])
  const [filteredEndUsers, setFilteredEndUsers] = useState<OrganizationUser[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [sortingTitle, setSortingTitle] = useState<string>(t('common.name'))
  const [sortAsc, setSortAsc] = useState(true)
  const [sortedUsers, setSortedUsers] = useState<OrganizationUser[]>([])

  useEffect(() => {
    openModal && firstLogin() && openUserManagementInfoModal(setModal, t, step, setStep)
  }, [setModal, t, step])

  useEffect(() => {
    setLoading(true)
    getOrganizationUsers(createCancelToken().token)
      .then(setEndUsers)
      .catch(error => {
        if (!axios.isCancel(error)) setLoading(false)
        handleError(error)
      })
      .finally(() => setLoading(false))

    return () => {
      setLoading(false)
    }
  }, [createCancelToken, handleError])

  useEffect(() => {
    setSortedUsers(
      filteredEndUsers
        .sort((a, b) => {
          const sorting =
            sortingTitle === t('common.name')
              ? a.firstName.toLowerCase() > b.firstName.toLowerCase()
                ? 1
                : -1
              : sortingTitle === t('common.email')
                ? a.emailAddress.toLowerCase() > b.emailAddress.toLowerCase()
                  ? 1
                  : -1
                : sortingTitle === t('admin.usersList.roles')
                  ? a.roles.toString() > b.roles.toString()
                    ? 1
                    : -1
                  : (a.lastLoginAt ?? new Date(0)).getTime() > (b.lastLoginAt ?? new Date(0)).getTime()
                    ? 1
                    : -1

          return sortAsc ? sorting : -sorting
        })
        .map(user => ({ ...user }))
    )
  }, [sortingTitle, sortAsc, filteredEndUsers])

  useEffect(() => {
    if (searchText !== '')
      setFilteredEndUsers(
        endUsers.filter(
          user =>
            user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
            user.emailAddress.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    else setFilteredEndUsers(endUsers)
  }, [endUsers, searchText])

  const createUser = (firstName: string, lastName: string, emailAddress: string) => {
    adminCreateUser(firstName, lastName, emailAddress)
      .then((userId: string) => {
        setModal(null)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('admin.usersList.createUserSuccessToast', { name: `${firstName} ${lastName}` })
        })
        navigate(`/admin/users/${userId}?new=true`)
      })
      .catch(handleError)
  }

  const deleteUser = (user: OrganizationUser) => {
    adminDeleteUser(user.id)
      .then((users: OrganizationUser[]) => {
        setEndUsers(users)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('admin.usersList.deleteUserSuccessToast', { name: `${user.firstName} ${user.lastName}` })
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const openCreateModal = () => {
    setModal({
      header: t('admin.usersList.modal.addHeader'),
      body: <CreateUserModal submitAction={createUser} />
    })
  }

  const openDeleteModal = (user: OrganizationUser) => {
    setModal({
      header: t('admin.usersList.modal.deleteHeader'),
      body: <DeleteUserModal submitAction={deleteUser} user={user} />
    })
  }

  return (
    <Layout type={'sub'}>
      <NestedPageHeader
        mainHeading={t('admin.usersList.heading')}
        capitalize={true}
        subHeading={<GrayText>{t('admin.usersList.subHeading')}</GrayText>}
        actions={
          <>
            {!authInfo.isSsoEnabled ? (
              <Button value={t('admin.usersList.createUser')} clickHandler={openCreateModal} />
            ) : (
              <GrayText className={'text-80 lg:w-max'}>{t('admin.usersList.ssoNewUsers')}</GrayText>
            )}
            <HelpTooltip clickHandler={() => openUserManagementInfoModal(setModal, t, step, setStep)} />
          </>
        }
      />
      <AdminContentWrapper>
        {loading ? (
          <Loading />
        ) : (
          <div className={'flex flex-col gap-3'}>
            <SearchInput
              placeholder={t('admin.usersList.searchPlaceholder')}
              searchText={searchText}
              setSearchText={setSearchText}
            />
            {sortedUsers.length > 0 ? (
              <ScrollTable
                smallScreenTitle={t('admin.usersList.users')}
                customColumns={'repeat(5, 1fr) minmax(100px, auto)'}
                sortable={true}
                sortingTitle={sortingTitle}
                setSortingTitle={setSortingTitle}
                sortAsc={sortAsc}
                setSortAsc={setSortAsc}
                titles={[
                  t('common.name'),
                  t('common.email'),
                  t('admin.usersList.roles'),
                  t('admin.usersList.visibility'),
                  t('admin.usersList.lastLogin')
                ]}
                rows={sortedUsers.map(user => (
                  <UserRow key={user.id} user={user} openDeleteModal={openDeleteModal} />
                ))}
              />
            ) : (
              <MissingDataNotification displayText={t('admin.userNotFound_other')} />
            )}
          </div>
        )}
      </AdminContentWrapper>
    </Layout>
  )
}

interface UserRowProps {
  user: OrganizationUser
  openDeleteModal: (user: OrganizationUser) => void
}

const UserRow = ({ user, openDeleteModal }: UserRowProps) => {
  const navigate = useNavigate()
  const { authInfo } = useContext(UserInfoContext)
  const { t } = useTranslation()

  const userCanBeEdited =
    authInfo.id !== user.id &&
    (authInfo.roles.includes(UserRole.ADMIN) ||
      (authInfo.roles.some(role => ADMIN_ROLES.includes(role)) &&
        !authInfo.roles.includes(UserRole.ADMIN) &&
        !user.roles.includes(UserRole.ADMIN)))

  const userAccessRoles = user.roles.filter(role => ACCESS_RIGHT_ROLES.includes(role) || role === UserRole.SYSTEM_ADMIN)

  return (
    <>
      <ScrollTableRowItem>
        <CapitalizedText hideOnWideScreen={true}>{t('common.name')}</CapitalizedText>
        {`${user.firstName} ${user.lastName}`}
      </ScrollTableRowItem>
      <ScrollTableRowItem>
        <CapitalizedText hideOnWideScreen={true}>{t('common.email')}</CapitalizedText>
        {user.emailAddress}
      </ScrollTableRowItem>
      <ScrollTableRowItem>
        <CapitalizedText hideOnWideScreen={true}>{t('admin.usersList.roles')}</CapitalizedText>
        <div>
          {userAccessRoles.length ? (
            userAccessRoles.map(role => (
              <CapitalizedText key={role} capitalizeAll={true}>
                {t(`admin.roles.${role}`)}
              </CapitalizedText>
            ))
          ) : (
            <DimmedLabel>{t('admin.usersList.noAccess')}</DimmedLabel>
          )}
        </div>
      </ScrollTableRowItem>
      <ScrollTableRowItem>
        <CapitalizedText hideOnWideScreen={true}>{t('admin.usersList.visibility')}</CapitalizedText>
        <VisibilityDescription user={user} />
      </ScrollTableRowItem>
      <ScrollTableRowItem>
        <CapitalizedText hideOnWideScreen={true}>{t('admin.usersList.lastLogin')}</CapitalizedText>
        {formatDate(user.lastLoginAt, true, false, true)}
      </ScrollTableRowItem>
      <ScrollTableRowItem>
        {user.emailAddress !== authInfo.emailAddress && (
          <div className={'flex flex-row gap-4 items-center m-auto'}>
            <Button
              type={ButtonType.ICON}
              clickHandler={() => navigate(user.id)}
              buttonStyle={ButtonStyle.PRIMARY}
              value={
                <CustomIcon
                  iconType={IconType.VECTOR}
                  path={editIcon}
                  alt={t('common.edit')}
                  styles={'bg-gray-50 w-5 h-5'}
                />
              }
            />

            <DeleteButton
              size={6}
              clickHandler={userCanBeEdited ? () => openDeleteModal(user) : undefined}
              tooltipText={t('common.delete')}
            />
          </div>
        )}
      </ScrollTableRowItem>
    </>
  )
}

interface VisibilityDescriptionProps {
  user: OrganizationUser
}

const VisibilityDescription = ({ user }: VisibilityDescriptionProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const permittedFeatures = user.roles.filter(role => VISIBILITY_ROLES.includes(role))

  const hasFullVisibility = (): boolean => {
    if (user.roles.some(role => ADMIN_ROLES.includes(role))) return true
    if (authInfo.freshIntegration) {
      return permittedFeatures.length === VISIBILITY_ROLES.length
    } else {
      return permittedFeatures.length === VISIBILITY_ROLES.length - 1
    }
  }

  return (
    <>
      {hasFullVisibility() ? (
        <CapitalizedText className={'break-normal'}>{t('admin.usersList.fullAccess')}</CapitalizedText>
      ) : permittedFeatures.length ? (
        <div>
          {permittedFeatures.map(role => (
            <CapitalizedText key={role} capitalizeAll={true}>
              {t(`admin.roles.${role}`)}
            </CapitalizedText>
          ))}
        </div>
      ) : (
        <DimmedLabel>{t('admin.usersList.noAccess')}</DimmedLabel>
      )}
    </>
  )
}

interface CreateUserModalProps {
  submitAction: (firstName: string, lastName: string, emailAddress: string) => void
}

const CreateUserModal = ({ submitAction }: CreateUserModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = useForm<CreateUserForm>()

  const onSubmit = (data: CreateUserForm) => {
    submitAction(data.firstName.trim(), data.lastName.trim(), data.emailAddress.trim())
  }

  interface CreateUserForm {
    firstName: string
    lastName: string
    emailAddress: string
  }

  return (
    <form>
      <div className={'flex flex-col gap-4'}>
        <div>
          <CustomLabel>{t('common.firstName')} *</CustomLabel>
          <CustomInput
            {...register('firstName', {
              required: true,
              validate: value => value.trim().length > 0
            })}
            autoFocus={true}
          />
        </div>
        <div>
          <CustomLabel>{t('common.lastName')} *</CustomLabel>
          <CustomInput
            {...register('lastName', {
              required: true,
              validate: value => value.trim().length > 0
            })}
          />
        </div>
        <div>
          <CustomLabel>{t('common.emailAddress')} *</CustomLabel>
          <CustomInput
            type={'email'}
            {...register('emailAddress', {
              required: true,
              validate: value => testCognitoEmail(value)
            })}
          />
        </div>
      </div>
      <ModalActions>
        <Button
          value={t('common.cancel')}
          clickHandler={() => setModal(null)}
          type={ButtonType.FORM}
          buttonStyle={ButtonStyle.SECONDARY}
        />
        <Button
          disabled={!isValid || isSubmitting}
          value={t('admin.usersList.modal.create')}
          clickHandler={handleSubmit(onSubmit)}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </form>
  )
}

interface DeleteUserModalProps {
  submitAction: (user: OrganizationUser) => void
  user: OrganizationUser
}

const DeleteUserModal = ({ submitAction, user }: DeleteUserModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)

  return (
    <form>
      <div className={'flex flex-col gap-4'}>
        <ModalText>
          <CapitalizedText>
            {t('admin.usersList.modal.deleteText', { name: `${user.firstName} ${user.lastName}` })}
          </CapitalizedText>
        </ModalText>
      </div>
      <ModalActions>
        <Button
          value={t('common.cancel')}
          clickHandler={() => setModal(null)}
          type={ButtonType.FORM}
          buttonStyle={ButtonStyle.SECONDARY}
        />
        <Button
          disabled={requestInFlight}
          value={t('common.delete')}
          type={ButtonType.FORM}
          clickHandler={() => {
            setRequestInFlight(true)
            submitAction(user)
          }}
        />
      </ModalActions>
    </form>
  )
}

const DimmedLabel = styled(CapitalizedText)`
  ${tw`text-gray-300`}
`

interface UserManagementInfoModalProps {
  step: 0 | 1
  setStep: (step: 0 | 1) => void
}

const UserManagementInfoModal = ({ step, setStep }: UserManagementInfoModalProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const { setModal } = useContext(ModalContext)
  const infoRows = [
    <HelpTooltipInfoBlock iconPath={adminIcon} text={t('admin.usersList.userManagementInfoModal.text_3')} />
  ]
  !authInfo.isSsoEnabled
    ? infoRows.push(
        <HelpTooltipInfoBlock iconPath={userIcon} text={t('admin.usersList.userManagementInfoModal.text_4')} />
      )
    : infoRows.push(
        <HelpTooltipInfoBlock iconPath={userIcon} text={t('admin.usersList.userManagementInfoModal.ssoNewUsers')} />
      )
  infoRows.push(
    <HelpTooltipInfoBlock iconPath={keyIcon} text={t('admin.usersList.userManagementInfoModal.text_5')} />,
    <HelpTooltipInfoBlock iconPath={profileIcon} text={t('admin.usersList.userManagementInfoModal.text_6')} />
  )

  if (step === 0)
    return (
      <HelpTooltipModal
        contentRows={[
          t('admin.usersList.userManagementInfoModal.text_1'),
          t('admin.usersList.userManagementInfoModal.text_2')
        ]}
        actions={<Button value={t('common.next')} size={ButtonSize.XSMALL} clickHandler={() => setStep(1)} />}
      />
    )

  return (
    <HelpTooltipModal
      contentRows={infoRows}
      actions={
        <Button
          value={t('common.gotIt')}
          size={ButtonSize.XSMALL}
          clickHandler={() => {
            removeFirstLogin()
            setModal(null)
          }}
        />
      }
    />
  )
}

const openUserManagementInfoModal = (
  setModal: (modal: SpotterModal) => void,
  t: TFunction,
  step: 1 | 0,
  setStep: (step: 0 | 1) => void
) => {
  setModal({
    header: t('admin.usersList.userManagementInfoModal.header'),
    returnAction: firstLogin() && step === 1 ? () => setStep(0) : undefined,
    body: <UserManagementInfoModal step={step} setStep={setStep} />
  })
}
